
import { reactive } from "vue";
import axios from "axios";
import {  useRouter } from 'vue-router';
export default {
     name:"AdminCountryCreate",
 setup() {
    const data  =reactive({
        name:'',
        country_code:'',
        currency_short_name:'',
        curreny_standard_name:'',
        country_time_zone:'',
        country_topic:'',
        is_show:'',
        distance_type:'',
        country_ios_code:''
    });
   
      const router = useRouter();
     

const submit =async ()=>{

await axios.post(`/countries`,data);

await router.push('/countries');
}


    return {
        data,
     
      submit
    };
  },
};
