
import { onMounted, reactive, ref, watch } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
import AdminImageUpload from  "@/components/AdminImageUpload.vue";
export default {
     name:"AdminNotificationCreate",
     components: {
     'AdminImageUpload': AdminImageUpload,
       },
 setup() {
    const data  =reactive({
        title:'',
        description:'',
        image_url:'',
        country_id:1,
        city_id:'',
    });
   
      const router = useRouter();
      const cities =ref([]);
      const countries =ref([]);

    onMounted(async ()=>{
      const  citiesResponse = await axios.get(`cities/country/${data.country_id}`);
      cities.value = citiesResponse.data;
      const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
 

    });

   watch(data, async()=>{

           const  citiesResponse = await axios.get(`cities/country/${data.country_id}`);
      cities.value = citiesResponse.data;
        });



const submit =async ()=>{

await axios.post(`/notifications`,data);

await router.push('/notifications');
}


    return {
        data,
         countries,
        cities,
      submit
    };
  },
};
