import { Role } from "@/models/role";
import { Country } from "@/models/country";
export class User{
    constructor(
        public id: number =0,
        public full_name: string='',
        public email: string='',
        public bussiness_name: string ='',
        public phone: string ='',
        public role: Role = new Role(),
        public country: Country = new Country()

    ){

    }
}