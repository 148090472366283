
import { onMounted, ref } from "vue";
import axios from "axios";
import { Booking } from "@/models/booking";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import moment from "moment";
export default {
  name: "AdminBookingList",
  components: { VPagination },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const bookings = ref([]);

    const lastPage = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `booking?page=${page}&take=50`
      );
      bookings.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`booking/${id}`);

        bookings.value = bookings.value.filter((u: Booking) => u.id !== id);
      }
    };

    return {
      bookings,
      lastPage,
      del,
      load,
    };
  },
};
