
import { onMounted, ref } from "vue";
import axios from "axios";
import { Rider } from "@/models/rider";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminDriverListLive",
 
  setup() {
    const riders = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const search = ref("");
    const city_id = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `drivers/live/list?page=${page}&take=500&search=${search.value}&city_id=${city_id.value}`
      );
      riders.value = data;
     
    };

    onMounted(load);
    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        //  await axios.delete(`riders/${id}`);

        riders.value = riders.value.filter((u: Rider) => u.id !== id);
      }
    };


 setInterval(function () {
      load();
    }, 10000);

    const searchcall = async () => {
      load();
    };
    const searchclear = async () => {
      search.value = "";
      load();
    };
    return {
      riders,
      lastPage,
      del,
      search,
      load,
      selected,
      select,
      searchcall,
      searchclear,
    };
  },
};
