<template lang="">

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-sm-8 col-md-7 col-lg-4 mx-auto align-self-center">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <h2 class="card-title text-center mb-5 fw-bold"> Please Sign In</h2>


             <div class="alert alert-danger d-flex align-items-center" role="alert"  v-if="errorserver && errorserver.length">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div>
               
                <div v-for="elem in errorserver" :key="elem.index">
          <span>{{ elem }}</span><br/>
           </div>
              </div>
          </div>
               <form @submit.prevent="submit">
              <div class="form-floating mb-3">
                <input type="email"  v-model="form.email" class="form-control" placeholder="Email" required />
                <label for="floatingInput">Email address</label>
              </div>
              <div class="form-floating mb-3">
                 <input type="password" v-model="form.password" class="form-control" placeholder="Password" required />
                <label for="floatingPassword">Password</label>
              </div>

              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck">
                <label class="form-check-label" for="rememberPasswordCheck">
                  Remember password
                </label>
              </div>
              <div class="d-grid">
                <button class="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Sign
                  in</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import  {reactive,ref} from "vue";
import axios  from "axios";
import { useRouter } from 'vue-router';
export default {
  name:"AdminLogin",
  
  setup(){
    const form =reactive({
      email:'',
      password:''
    });

   const errorserver = ref([]);

const router =useRouter();
 
const submit =async ()=>{
  await axios.post('/adminlogin',{
          email:form.email,
          password:form.password,
          device_type:"Web",
          login_type:"Manual",
          fcm_token:"1234543534534456",
          social_id:""

        },{withCredentials: true}).then((response) => {
      router.push('/');
}).catch((error) => {
        //  this.errorMessage=error.response.data.message[0];
            errorserver.value=error.response.data.message;
   // console.log(error.response.data.message[0]);
});
 //.then((response) => {
 //    console.log('Everything is awesome.');
//})
 //localStorage.setItem('token', data.token);

}

    return {
      form,
      submit,
      errorserver
    }
  }  
}
</script>
<style  scoped>
html,
body {
height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  height: 100vh;
  background: #007bff!important;
  background: linear-gradient(to right, #0062E6, #33AEFF)!important;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>