
import { onMounted, ref } from "vue";
import axios from "axios";
import { Withdrawrequest } from "@/models/withdrawrequest";
import AdminPagination from "@/components/AdminPagination.vue";
export default {
  name: "AdminWithdrawRequestList",
  components: { AdminPagination },
  setup() {
    const withdrawrequest = ref([]);
    const lastPage = ref(0);
    const search = ref("");

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `withdrawrequest?page=${page}&take=50&search=${search.value}`
      );
      withdrawrequest.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`withdrawrequest/${id}`);

        withdrawrequest.value = withdrawrequest.value.filter(
          (u: Withdrawrequest) => u.id !== id
        );
      }
    };
    const searchcall = async () => {
      load();
    };
    const searchclear = async () => {
      search.value = "";

      load();
    };

    return {
      withdrawrequest,
      lastPage,
      search,
      del,
      load,
      searchcall,
      searchclear,
    };
  },
};
