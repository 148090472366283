
import { onMounted, ref } from "vue";
import axios from "axios";
import { Rider } from "@/models/rider";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminNotificationList",
  components: { VPagination },
  setup() {
    const notifications = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(`notifications?page=${page}`);
      notifications.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);
    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`notifications/${id}`);

        notifications.value = notifications.value.filter(
          (u: Rider) => u.id !== id
        );
      }
    };

    return {
      notifications,
      lastPage,
      del,

      load,
      selected,
      select,
    };
  },
};
