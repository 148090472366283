
import { onMounted, ref } from "vue";
import axios from "axios";
import { Peakfactor } from "@/models/peakfactor";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminCityDetail",
  setup() {
    const city = ref([]);

    const selected = ref(0);
    const peakfactors = ref([]);
    const riderrate = ref([]);

   const route = useRoute();

    const peakfactorapi = async () => {
      const { data } = await axios.get(`peakfactor/city/${route.params.id}`);
      peakfactors.value = data;
    };

    const riderratesapi = async () => {
      const { data } = await axios.get(`riderrates/city/${route.params.id}`);
      riderrate.value = data;
    };

    const citylistApi = async () => {
      const { data } = await axios.get(`/cities/${route.params.id}`);
      city.value = data;
    };

    onMounted(riderratesapi);
    onMounted(citylistApi);
    onMounted(peakfactorapi);
    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };

    const delpeakfactor = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`peakfactor/${id}`);
          peakfactors.value = peakfactors.value.filter((u: Peakfactor) => u.id !== id);
      }
    };

    return {
      city,
      peakfactors,
      selected,
      riderrate,
delpeakfactor,
      select,
    };
  },
};
