
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminPaymentmethodEdit",
 setup() {
    const data  =reactive({
        name:'',
        api_key:'',
        api_secret:'',
        api_password:'',
        is_show:'',
        is_external_call:'',
        country_id:'',
key_name:''
    });
   
      const router = useRouter();
      const route = useRoute();
      const countries =ref([]);

    onMounted(async ()=>{
      const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
      const response = await axios.get(`/paymentmethods/${route.params.id}`);
      
        data.name =response.data.name;
        data.api_key =response.data.api_key;
        data.api_secret =response.data.api_secret;
        data.api_password =response.data.api_password;
        data.is_show =response.data.is_show;
        data.is_external_call =response.data.is_external_call;
        data.country_id =response.data.country?.id;
        data.key_name=response.data.key_name;
       
     


    });

const submit =async ()=>{

await axios.put(`/paymentmethods/${route.params.id}`,data);

await router.push('/paymentmethods');
}

    return {
        data,
        countries,
      submit
    };
  },
};
