
import { onMounted, ref } from "vue";
import axios from "axios";
import { User } from "@/models/user";
import AdminPagination from "@/components/AdminPagination.vue";
import { useRoute } from "vue-router";
import moment from "moment";

export default {
  name: "AdminUserView",
  components: { AdminPagination },
  methods: {
    dateTime(value: any) {
      return moment(value).format("YYYY-MM-DD h:mm:ss a");
    },
  },
  setup() {
    const users = ref([]);
    const user = ref([]);
    const lastPage = ref(0);
    const devices = ref([]);
    const addresses = ref([]);
    const withdrawaccounts = ref([]);
    const withdrawrequest = ref([]);
    const rate = ref([]);
    const wallets = ref([]);
    const route = useRoute();
    const orders = ref([]);
    const selected = ref(0);
    const creditcards = ref([]);
    const ratesdata = async () => {
      const { data } = await axios.get(`rates/user/${route.params.id}`);
      rate.value = data;
    };

    const userdata = async () => {
      const { data } = await axios.get(`users/${route.params.id}`);
      user.value = data;
    };
    const useraddress = async () => {
      const { data } = await axios.get(
        `addresses/user/${route.params.id}?page=1&take=100`
      );
      addresses.value = data.data;
    };

    const userdevices = async () => {
      const { data } = await axios.get(`devices/user/${route.params.id}`);
      devices.value = data;
    };

    const withdrawaccountsmethod = async () => {
      const { data } = await axios.get(
        `withdrawaccounts/user/${route.params.id}?page=1&take=100`
      );
      withdrawaccounts.value = data.data;
    };

    const withdrawrequestmethod = async () => {
      const { data } = await axios.get(
        `withdrawrequest/user/${route.params.id}?page=1&take=100`
      );
      withdrawrequest.value = data.data;
    };

    const usercreditcards = async () => {
      const { data } = await axios.get(`creditcards/user/${route.params.id}`);
      creditcards.value = data;
    };

    const userwallethistory = async () => {
      const { data } = await axios.get(
        `wallet/user/${route.params.id}?page=1&take=200`
      );
      wallets.value = data.data;
    };

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `orders/user/${route.params.id}?page=${page}&take=50`
      );
      orders.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    const select = async (id: number) => {
      selected.value = selected.value !== id ? id : 0;
     
    };

    onMounted(userwallethistory);
    onMounted(load);

    onMounted(ratesdata);
    onMounted(userdata);
    onMounted(useraddress);
    onMounted(userdevices);
    onMounted(usercreditcards);
    onMounted(withdrawaccountsmethod);
    onMounted(withdrawrequestmethod);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`users/${id}`);

        users.value = users.value.filter((u: User) => u.id !== id);
      }
    };

    return {
      users,
      user,
      rate,
      lastPage,
      del,
      devices,
      addresses,
      orders,
      selected,
      withdrawaccounts,
      withdrawrequest,
      select,
      creditcards,
      load,
      wallets,
    };
  },
};
