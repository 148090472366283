
import { onMounted, ref } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
export default {
  name: "AdminRateDetail",
  setup() {
    const rate = ref([]);

    const selected = ref(0);


    const route = useRoute();

    const load =async () => {
      const { data } = await axios.get(`/rates/${route.params.id}`);
      rate.value = data;
    }

    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };

    onMounted(load);

    const delraterange =async (id:number)=>{
  if(confirm('Are You Sure?')){
    await axios.delete(`rateranges/${id}`);

    load();

  }

}
    return {
      rate,
      delraterange,
      selected,
      select,
    };
  },
};
