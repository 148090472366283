
import { onMounted, ref } from "vue";
import axios from "axios";
import { Rider } from "@/models/rider";
import { Raterange } from "@/models/raterange";
import AdminPagination from "@/components/AdminPagination.vue";
export default {
  name: "AdminRiderList",
  components: { AdminPagination },
  setup() {
    const riders = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const search = ref("");

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `riders?page=${page}&take=50&search=${search.value}`
      );
      riders.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);
    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        //  await axios.delete(`riders/${id}`);

        riders.value = riders.value.filter((u: Rider) => u.id !== id);
      }
    };

    const searchcall = async () => {
      load();
    };
    const searchclear = async () => {
      search.value = "";

      load();
    };
    return {
      riders,
      lastPage,
      del,
      search,
      load,
      selected,
      select,
      searchcall,
      searchclear,
    };
  },
};
