import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "btn btn-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createTextVNode("Upload"),
    _createElementVNode("input", {
      type: "file",
      hidden: "",
      onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.upload($event.target.files)))
    }, null, 32)
  ]))
}