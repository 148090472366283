
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminRiderrateEdit",
  setup() {
    const data = reactive({
      distance_type: "",
      per_unit_price: "",
      base_price: "",
      base_unit: "",
      is_base_rate: "",
      is_reference_rate: "",
      is_advance_rate: "",
      aditional_per_order_price: 0,
      aditional_order_same_client: 0,
      aditional_order_diff_client: 0,
      city_id: 0,
    });

    const router = useRouter();
    const route = useRoute();
    const cities = ref([]);

    onMounted(async () => {
      const response = await axios.get(`/riderrates/${route.params.id}`);

      data.distance_type = response.data.distance_type;
      data.per_unit_price = response.data.per_unit_price;
      data.base_unit = response.data.base_unit;
      data.base_price = response.data.base_price;
      data.is_base_rate = response.data.is_base_rate;
      data.is_reference_rate = response.data.is_reference_rate;
      data.is_advance_rate = response.data.is_advance_rate;
      data.aditional_per_order_price = response.data.aditional_per_order_price;
      data.aditional_order_same_client = response.data.aditional_order_same_client;
      data.aditional_order_diff_client = response.data.aditional_order_diff_client;
      data.city_id = response.data.city?.id;
    });

    const submit = async () => {
      await axios.put(`/riderrates/${route.params.id}`, data);

      await router.push(`/cities/${data.city_id}/detail`);
    };

    return {
      data,
      cities,
      submit,
    };
  },
};
