
  import { onMounted, ref } from "vue";
  import axios from "axios";
  import { Driver } from "@/models/driver";
  import moment from "moment";


  import { useRoute, useRouter } from "vue-router";

  export default {
    name: "AdminDriverDetailList",

    methods: {
      dateTime(value: any) {
        return moment(value).format("DD-MMM-YYYY HH:mm");
      },
    },
    setup() {
      const riderearnings = ref([]);
      const riderwallets = ref([]);
      const lastPage = ref(0);
      const selected = ref(0);
      const search = ref("");
      const rider = ref([]);
      const route = useRoute();



      const riderdata = async () => {
        const { data } = await axios.get(`drivers/${route.params.id}`);
        rider.value = data;
      };

      const select = (id: number) => {
        selected.value = selected.value !== id ? id : 0;
      };

      onMounted(riderdata);

      const del = async (id: number) => {
        if (confirm("Are You Sure?")) {
          //  await axios.delete(`riders/${id}`);

          riderearnings.value = riderearnings.value.filter(
            (u: Driver) => u.id !== id
          );
        }
      };


      return {

        lastPage,
        rider,
        del,
        search,

        selected,
        select,

        riderwallets,
      };
    },
  };
