
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import {  useRouter } from "vue-router";
export default {
  name: "AdminDriverCreate",
  setup() {
    const data = reactive({
      rider_name: "",
      rider_email: "",
      rider_phone: "",
      is_buzy: 0,
      availability_status: 0,
      is_active:0,
      is_block:0,
      rider_dob:"",
      rider_registration_no:"",
      rider_postal_code:"",
      bank_account_title:"",
      bank_account_number:"",
      bank_sort_code:"",
      bank_other_detail:"",
      rider_password:'',
      country_id:'',
      city_id:'',
      account_type:'Personal',
      device_type:'Web',
      registration_type:'Manual',
      login_type:'Manual',
      vehicle_type:'Scooter'
    });

  const countries =ref([]);
    const cities =ref([]);
  const router = useRouter();
 
  onMounted(async ()=>{
     const  citiesResponse = await axios.get('cities/all/list');
      cities.value = citiesResponse.data;
    const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
    });


    const submit = async () => {
      await axios.post(`/driverregister`, data);

      await router.push("/drivers");
    };

    return {
      data,
      countries,
      cities,
      submit,
    };
  },
};
