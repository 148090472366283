
import { onMounted,  ref } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import moment from "moment";
export default {
  name: "AdminOrderLogList",
    methods: {
    dateTime(value: any) {
      return moment(value).format("YYYY-MM-DD h:mm:ss a");
    },
  },
  setup() {
    const order = ref([]);
    const orderpushs = ref([]);
    const ordereditlogs = ref([]);

 
    const route = useRoute();



    const orderfunc = async () => {
      const { data } = await axios.get(`orders/${route.params.id}`);
      order.value = data;
    };
    const ordereditfunc = async () => {
      const { data } = await axios.get(`order-edit-log/order/${route.params.id}`);
      ordereditlogs.value = data;
    };


   const getorderpush = async () => {
      const { data } = await axios.get(`orderpush/order/${route.params.id}`);
      orderpushs.value = data.data;
    };
 
onMounted(ordereditfunc);
onMounted(getorderpush);
onMounted(orderfunc);

    return {
      orderpushs,
      ordereditlogs,
      order
    };
  },
};
