<template lang="">
  <nav class="navbar navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="logo.png" alt="" width="120" height="24">
      </a>
    </div>
  </nav>

   <div class="container-fluid">
      <div class="row">
        <dic class="col-12">
           <h2>{{ data.title}}</h2>

           <p v-html="data.detail"> </p>
        </dic>
      </div>
  </div>

  
 
</template>
<script>
import  {reactive,onMounted} from "vue";
import   axios   from 'axios';
 
export default {
  name:"AdminTnc",
    
 setup() {
    const data  =reactive({
        title:'',
      detail:'',
      slug:'',

    });
  
    
    

    onMounted(async ()=>{
     
      const response = await axios.get(`/cms/3`);
     
      data.title =response.data.title;
      data.slug =response.data.slug;
      data.detail =response.data.detail;
      
          
    });

 


    return {
        data,
  
      
    };
  },
};
</script>
<style  scoped>
 
</style>