
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
     name:"AdminCmsEdit",
       components: {
     QuillEditor
       },
 setup() {
    const data  =reactive({
        title:'',
      detail:'',
      slug:'',

    });
   const contentdata = ref(null)
      const router = useRouter();
      const route = useRoute();
    

    onMounted(async ()=>{
     
      const response = await axios.get(`/cms/${route.params.id}`);
     
      data.title =response.data.title;
      data.slug =response.data.slug;
      data.detail =response.data.detail;
      
          contentdata.value.setHTML(response.data.detail);
    });

const submit =async ()=>{

await axios.put(`/cms/${route.params.id}`,data);

await router.push('/cms');
}


    return {
        data,
    contentdata,
      submit
    };
  },
};
