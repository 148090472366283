
import { onMounted, ref } from "vue";
import axios from "axios";
import { Order } from "@/models/order";
import moment from "moment";
import AdminPagination from "@/components/AdminPagination.vue";
import Datepicker from "vue3-datepicker";

export default {
  name: "AdminRiderEarningSearchList",
  components: { AdminPagination, datepicker: Datepicker },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const orders = ref([]);
    const summary = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const rider = ref([]);
    const riders = ref([]);

    const fromdate = ref(new Date());
    const todate = ref(new Date());
    const rider_id = ref(0);

    const load = async () => {
      var custodate = moment(todate.value).format("YYYY-MM-DD");
      var cusfromdate = moment(fromdate.value).format("YYYY-MM-DD");
      const { data } = await axios.get(
        `riders/earning/${rider_id.value}?todate=${custodate}&fromdate=${cusfromdate}`
      );
      orders.value = data.data.data;
      summary.value = data.summary;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const loadusers = async () => {
      const { data } = await axios.get(`riders/alllist`);
      riders.value = data;
    };

    onMounted(loadusers);

    const searchcall = async () => {
      load();
    };

    return {
      orders,
      lastPage,
      summary,
      load,
      selected,
      rider,
      searchcall,
      rider_id,
      fromdate,
      todate,
      riders,
    };
  },
};
