
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';
export default {
  name: "AdminRoleCreate",
  setup() {
    const formData  =reactive({
      name:'',
      permissions:[]
     

    });
   
      const router = useRouter();
    
      const permissionList=ref([]);
    onMounted(async ()=>{
      const {data} = await axios.get('permissions');
      permissionList.value= data;

    });

const submit =async ()=>{

await axios.post('roles',formData);

await router.push('/roles');
}


const select =(id: number, checkecd:boolean)=>{
      if(checkecd){
         formData.permissions=[...formData.permissions, id];
         return;
      }
 formData.permissions= formData.permissions.filter(p => p !==id);

}


    return {
        formData,
        permissionList,
        select,
      submit
    };
  },
};
