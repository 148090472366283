
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminBookingEdit",
 setup() {
    const data  =reactive({
        name:'',
        name_urdu:'',
        country_id:'',
        city_topic:'',
        is_show:'true',
        is_second_order_cron:'true',
        is_third_order_cron:'false',
        booking_price_fixed:0,
        booking_price_flexible:0,
        booking_flexible_note:''

    });
   
      const router = useRouter();
      const route = useRoute();
     const countries =ref([]);

    onMounted(async ()=>{
      const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
      const response = await axios.get(`/cities/${route.params.id}`);
      console.log(response);
      data.name =response.data.name;
      data.name_urdu =response.data.name_urdu;
      data.city_topic =response.data.city_topic;
      data.country_id =response.data.country.id;
      data.is_show =response.data.is_show;
      data.is_second_order_cron =response.data.is_second_order_cron;
      data.is_third_order_cron =response.data.is_third_order_cron;
      data.booking_price_fixed =response.data.booking_price_fixed;
      data.booking_price_flexible =response.data.booking_price_flexible;
      data.booking_flexible_note =response.data.booking_flexible_note;


    });

const submit =async ()=>{

await axios.put(`/cities/${route.params.id}`,data);

await router.push('/cities');
}


    return {
        data,
        countries,
      submit
    };
  },
};
