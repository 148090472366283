
import { onMounted, ref } from "vue";
import axios from "axios";
import { Addressuser } from "@/models/addressuser";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminAddressList",
  components: { VPagination },
  setup() {
    const addresslist = ref([]);

    const lastPage = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(`addresses?page=${page}`);
      addresslist.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`addresses/${id}`);

        addresslist.value = addresslist.value.filter(
          (u: Addressuser) => u.id !== id
        );
      }
    };

    return {
      addresslist,
      lastPage,
      del,
      load,
    };
  },
};
