
import { onMounted, ref } from "vue";
import axios from "axios";
import moment from "moment";
import Datepicker from "vue3-datepicker";

export default {
  name: "AdminDriverEarningAllSearchList",
  components: {  datepicker: Datepicker },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const orders = ref([]);
    const summary = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const rider = ref([]);
    const riders = ref([]);

    const fromdate = ref(new Date());
    const todate = ref(new Date());
    const driver_id = ref(0);

    const load = async () => {
      var custodate = moment(todate.value).format("YYYY-MM-DD");
      var cusfromdate = moment(fromdate.value).format("YYYY-MM-DD");
      const { data } = await axios.get(
        `drivers/earningreport/all?todate=${custodate}&fromdate=${cusfromdate}`
      );
     
      console.log(data.summary);
      summary.value = data.summary;
      console.log(summary);
    
    };

    onMounted(load);


    const downloadpdf = async () => {
      var custodate = moment(todate.value).format("YYYY-MM-DD");
      var cusfromdate = moment(fromdate.value).format("YYYY-MM-DD");
      const { data } = await axios.get(
        `orders/riderpdf/${driver_id.value}/generate?todate=${custodate}&fromdate=${cusfromdate}`,
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "test.pdf";
      link.click();
    };
    const loadusers = async () => {
      const { data } = await axios.get(`drivers/all/list`);
      riders.value = data;
    };

    onMounted(loadusers);

    const searchcall = async () => {
      load();
    };

    return {
      orders,
      
      summary,
      load,
      selected,
      rider,
      searchcall,
      downloadpdf,
      driver_id,
      fromdate,
      todate,
      riders,
    };
  },
};
