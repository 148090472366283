
import { onMounted, reactive } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminForceupdateEdit",
  setup() {
    const data = reactive({
      device_type: "",
      version_code: "",
      version_name: "",
      is_force_update: "",
      app_type:"",
      is_db_schema_change: "",
    });

    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      const response = await axios.get(`/forceupdate/${route.params.id}`);

      data.device_type = response.data.device_type;
      data.version_code = response.data.version_code;
      data.version_name = response.data.version_name;
      data.is_force_update = response.data.is_force_update;
      data.app_type = response.data.app_type;
      data.is_db_schema_change = response.data.is_db_schema_change;
    });

    const submit = async () => {
      await axios.put(`/forceupdate/${route.params.id}`, data);

      await router.push("/forceupdate");
    };

    return {
      data,

      submit,
    };
  },
};
