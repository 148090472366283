
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminRaterangeEdit",
 setup() {
    const data  =reactive({
        start_range:'',
        end_range:'',
        range_price:'',
        rate_price_type:'',
      
              

    });
   
      const router = useRouter();
      const route = useRoute();
     
   const rate_id = ref([]);

    onMounted(async ()=>{
      
      const response = await axios.get(`/rateranges/${route.params.id}`);
      
        data.start_range =response.data.start_range;
        data.end_range =response.data.end_range;
        data.range_price =response.data.range_price;
        data.rate_price_type=response.data.rate_price_type; 
        rate_id.value = response.data.rate.id;
        
    });

const submit =async ()=>{

await axios.put(`/rateranges/${route.params.id}`,data);

  await router.push(`/rates/detail/${rate_id.value}`);
}


    return {
        data,
        
      submit
    };
  },
};
