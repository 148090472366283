import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AdminDashBoard from '../pages/AdminDashBoard.vue'

import AdminUserList from '../pages/users/AdminUserList.vue'
import AdminUserView from '../pages/users/AdminUserView.vue'

import AdminUserRegister from '../pages/AdminUserRegister.vue'
import AdminLayout from '../pages/AdminLayout.vue'
import AdminLogin from '@/pages/AdminLogin.vue'
import AdminUserCreate from '@/pages/users/AdminUserCreate.vue'
import AdminUserEdit from '@/pages/users/AdminUserEdit.vue'
import AdminRoleList from '@/pages/roles/AdminRoleList.vue'
import AdminRoleCreate from '@/pages/roles/AdminRoleCreate.vue'
import AdminRoleEdit from '@/pages/roles/AdminRoleEdit.vue'
import AdminPackageList from '@/pages/packages/AdminPackageList.vue'
import AdminPackageCreate from '@/pages/packages/AdminPackageCreate.vue'
import AdminPackageEdit from '@/pages/packages/AdminPackageEdit.vue'

import AdminCountryList from '@/pages/countries/AdminCountryList.vue'
import AdminCountryCreate from '@/pages/countries/AdminCountryCreate.vue'
import AdminCountryEdit from '@/pages/countries/AdminCountryEdit.vue'

import AdminCityList from '@/pages/cities/AdminCityList.vue'
import AdminCityCreate from '@/pages/cities/AdminCityCreate.vue'
import AdminCityEdit from '@/pages/cities/AdminCityEdit.vue'
import AdminCityDetail from '@/pages/cities/AdminCityDetail.vue'


import AdminDeviceList from '@/pages/devices/AdminDeviceList.vue'
import AdminDeviceCreate from '@/pages/devices/AdminDeviceCreate.vue'
import AdminDeviceEdit from '@/pages/devices/AdminDeviceEdit.vue'

import AdminAddressList from '@/pages/addresses/AdminAddressList.vue'
import AdminAddressCreate from '@/pages/addresses/AdminAddressCreate.vue'
import AdminAddressEdit from '@/pages/addresses/AdminAddressEdit.vue'

import AdminConfigurationList from '@/pages/configurations/AdminConfigurationList.vue'
import AdminConfigurationCreate from '@/pages/configurations/AdminConfigurationCreate.vue'
import AdminConfigurationEdit from '@/pages/configurations/AdminConfigurationEdit.vue'

import AdminForceupdateList from '@/pages/forceupdate/AdminForceupdateList.vue'
import AdminForceupdateCreate from '@/pages/forceupdate/AdminForceupdateCreate.vue'
import AdminForceupdateEdit from '@/pages/forceupdate/AdminForceupdateEdit.vue'


import AdminRateList from '@/pages/rates/AdminRateList.vue'
import AdminRateCreate from '@/pages/rates/AdminRateCreate.vue'
import AdminRateDetail from '@/pages/rates/AdminRateDetail.vue'
import AdminRateEdit from '@/pages/rates/AdminRateEdit.vue'

import AdminRaterangeList from '@/pages/rateranges/AdminRaterangeList.vue'
import AdminRaterangeCreate from '@/pages/rateranges/AdminRaterangeCreate.vue'
import AdminRaterangeEdit from '@/pages/rateranges/AdminRaterangeEdit.vue'

import AdminOrderList from '@/pages/orders/AdminOrderList.vue'
import AdminOrderSearchList from '@/pages/orders/AdminOrderSearchList.vue'
import AdminOrderCreate from '@/pages/orders/AdminOrderCreate.vue'
import AdminOrderEdit from '@/pages/orders/AdminOrderEdit.vue'
import AdminOrderAdvanceEdit from '@/pages/orders/AdminOrderAdvanceEdit.vue'
import AdminOrderLogList from '@/pages/orders/AdminOrderLogList.vue'

import AdminOrderItemList from '@/pages/orderitems/AdminOrderItemList.vue'

import AdminOrderItemCreate from '@/pages/orderitems/AdminOrderItemCreate.vue'
import AdminOrderItemEdit from '@/pages/orderitems/AdminOrderItemEdit.vue'

import AdminProfile from '@/pages/AdminProfile.vue'

import AdminRiderList from '@/pages/riders/AdminRiderList.vue'
import AdminRiderCreate from '@/pages/riders/AdminRiderCreate.vue'
import AdminRiderEdit from '@/pages/riders/AdminRiderEdit.vue'
import AdminRiderDetailList from '@/pages/riders/AdminRiderDetailList.vue'
import AdminRiderEarningSearchList from '@/pages/riders/AdminRiderEarningSearchList.vue'


import AdminDriverList from '@/pages/driver/AdminDriverList.vue'
import AdminDriverListLive from '@/pages/driver/AdminDriverListLive.vue'

import AdminDriverCreate from '@/pages/driver/AdminDriverCreate.vue'
import AdminDriverEdit from '@/pages/driver/AdminDriverEdit.vue'
import AdminDriverDetailList from '@/pages/driver/AdminDriverDetailList.vue'
import AdminDriverViewList from '@/pages/driver/AdminDriverViewList.vue'
import AdminDriverEarningSearchList from '@/pages/driver/AdminDriverEarningSearchList.vue'
import AdminDriverEarningAllSearchList from '@/pages/driver/AdminDriverEarningAllSearchList.vue'



import AdminNotificationList from '@/pages/notifications/AdminNotificationList.vue'
import AdminNotificationCreate from '@/pages/notifications/AdminNotificationCreate.vue'
import AdminNotificationEdit from '@/pages/notifications/AdminNotificationEdit.vue'

import AdminPaymentmethodList from '@/pages/paymentmethods/AdminPaymentmethodList.vue'
import AdminPaymentmethodCreate from '@/pages/paymentmethods/AdminPaymentmethodCreate.vue'
import AdminPaymentmethodEdit from '@/pages/paymentmethods/AdminPaymentmethodEdit.vue'

import AdminCmsList from '@/pages/cms/AdminCmsList.vue'
import AdminCmsCreate from '@/pages/cms/AdminCmsCreate.vue'
import AdminCmsEdit from '@/pages/cms/AdminCmsEdit.vue'
import AdminFaq from '@/pages/cms/AdminFaq.vue'
import AdminAboutus from '@/pages/cms/AdminAboutus.vue'
import AdminTnc from '@/pages/cms/AdminTnc.vue'
import AdminPrivacypolicy from '@/pages/cms/AdminPrivacypolicy.vue'

import AdminSmsList from '@/pages/sms/AdminSmsList.vue'

import AdminDashBoardReport from '@/pages/AdminDashBoardReport.vue'

import AdminOrderTracking from '@/pages/tracking/AdminOrderTracking.vue'
import AdminOrderClientTracking from '@/pages/tracking/AdminOrderClientTracking.vue'
import AdminRiderTracking from '@/pages/tracking/AdminRiderTracking.vue'




import AdminOrderpushList from '@/pages/orderpush/AdminOrderpushList.vue'


import AdminPeakfactorList from '@/pages/peakfactor/AdminPeakfactorList.vue'
import AdminPeakfactorCreate from '@/pages/peakfactor/AdminPeakfactorCreate.vue'
import AdminPeakfactorEdit from '@/pages/peakfactor/AdminPeakfactorEdit.vue'

import AdminRiderrateList from '@/pages/riderrates/AdminRiderrateList.vue'
import AdminRiderrateCreate from '@/pages/riderrates/AdminRiderrateCreate.vue'
import AdminRiderrateDetail from '@/pages/riderrates/AdminRiderrateDetail.vue'
import AdminRiderrateEdit from '@/pages/riderrates/AdminRiderrateEdit.vue'



import AdminRiderraterangeList from '@/pages/riderrateranges/AdminRiderraterangeList.vue'
import AdminRiderraterangeCreate from '@/pages/riderrateranges/AdminRiderraterangeCreate.vue'
import AdminRiderraterangeEdit from '@/pages/riderrateranges/AdminRiderraterangeEdit.vue'


import AdminWithdrawRequestList from '@/pages/withdrawrequest/AdminWithdrawRequestList.vue'
import AdminWithdrawRequestEdit from '@/pages/withdrawrequest/AdminWithdrawRequestEdit.vue'

import AdminOrderCancelList from '@/pages/ordercancellog/AdminOrderCancelList.vue'
import AdminDriverLoginLogList from '@/pages/driverloginlog/AdminDriverLoginLogList.vue'

import AdminHourlyReport from '@/pages/reports/AdminHourlyReport.vue'
import AdminDailyReport from '@/pages/reports/AdminDailyReport.vue'
import AdminMonthlyReport from '@/pages/reports/AdminMonthlyReport.vue'
import AdminUserNotificationList from '@/pages/usernotification/AdminUserNotificationList.vue'
 
import AdminUserChat from '@/pages/chat/AdminUserChat.vue'

import AdminShiftCreate from '../pages/shifts/AdminShiftCreate.vue'
import AdminShiftEdit from '../pages/shifts/AdminShiftEdit.vue'
import AdminShiftList from '../pages/shifts/AdminShiftList.vue'
import AdminShiftView from '../pages/shifts/AdminShiftView.vue'

import AdminBookingCreate from '../pages/booking/AdminBookingCreate.vue'
import AdminBookingEdit from '../pages/booking/AdminBookingEdit.vue'
import AdminBookingList from '../pages/booking/AdminBookingList.vue'
import AdminBookingDetail from '../pages/booking/AdminBookingDetail.vue'



const routes: Array<RouteRecordRaw> = [
  { path: '/register', name: 'register', component: AdminUserRegister },
  { path: '/login', name: 'login', component: AdminLogin },
  { path: '/tnc', name: 'tnc', component: AdminTnc },
  { path: '/about-us', name: 'about-us', component: AdminAboutus },
  { path: '/privacypolicy', name: 'privacypolicy', component: AdminPrivacypolicy },
  { path: '/faq', name: 'faq', component: AdminFaq },
  { path: '/order/tracking/:id/customer', name: 'orderTrackingCustomer', component: AdminOrderTracking },
  { path: '/ot/:id/:pid', name: 'OTCDrivertracking', component: AdminOrderClientTracking },
  { path: '/tracking/driver/:id/location', name: 'driverTrackingCustomer', component: AdminRiderTracking },
  { path: '/userchat', name: 'adminuserchat', component: AdminUserChat },

  {
    path: '/',
    name: 'layout',
    component: AdminLayout,
    children: [
      { path: '/profile', name: 'profile', component: AdminProfile },
      { path: '/users', name: 'users', component: AdminUserList },
      { path: '/users/create', name: 'userCreate', component: AdminUserCreate },
      { path: '/users/:id/edit', name: 'userEdit', component: AdminUserEdit },
      { path: '/users/:id/view', name: 'userView', component: AdminUserView },

      { path: '/usernotifications', name: 'notificationslog', component: AdminUserNotificationList },

      { path: '', name: 'dashbaord', component: AdminDashBoard },
      { path: 'adminreport', name: 'adminreport', component: AdminDashBoardReport },
      { path: 'adminreporthourly', name: 'adminreporthourly', component: AdminHourlyReport },
      { path: 'adminreportdaily', name: 'adminreportdaily', component: AdminDailyReport },
      { path: 'adminreportmonthly', name: 'adminreportmonthly', component: AdminMonthlyReport },



      { path: '/roles', name: 'roles', component: AdminRoleList },
      { path: '/roles/create', name: 'roleCreate', component: AdminRoleCreate },
      { path: '/roles/:id/edit', name: 'roleEdit', component: AdminRoleEdit },


      { path: '/packages', name: 'packages', component: AdminPackageList },
      { path: '/packages/create', name: 'packageCreate', component: AdminPackageCreate },
      { path: '/packages/:id/edit', name: 'packageEdit', component: AdminPackageEdit },

      { path: '/countries', name: 'countries', component: AdminCountryList },
      { path: '/countries/create', name: 'countryCreate', component: AdminCountryCreate },
      { path: '/countries/:id/edit', name: 'countryEdit', component: AdminCountryEdit },

      { path: '/cities', name: 'cities', component: AdminCityList },
      { path: '/cities/create', name: 'cityCreate', component: AdminCityCreate },
      { path: '/cities/:id/edit', name: 'cityEdit', component: AdminCityEdit },
      { path: '/cities/:id/detail', name: 'cityDetail', component: AdminCityDetail },

      { path: '/addresses', name: 'addresses', component: AdminAddressList },
      { path: '/addresses/create', name: 'addressCreate', component: AdminAddressCreate },
      { path: '/addresses/:id/edit', name: 'addressEdit', component: AdminAddressEdit },

      { path: '/configurations', name: 'configurations', component: AdminConfigurationList },
      { path: '/configurations/create', name: 'configurationCreate', component: AdminConfigurationCreate },
      { path: '/configurations/:id/edit', name: 'configurationEdit', component: AdminConfigurationEdit },

      { path: '/forceupdate', name: 'forceupdate', component: AdminForceupdateList },
      { path: '/forceupdate/create', name: 'forceupdateCreate', component: AdminForceupdateCreate },
      { path: '/forceupdate/:id/edit', name: 'forceupdateEdit', component: AdminForceupdateEdit },


      { path: '/devices', name: 'devices', component: AdminDeviceList },
      { path: '/devices/create', name: 'deviceCreate', component: AdminDeviceCreate },
      { path: '/devices/:id/edit', name: 'deviceEdit', component: AdminDeviceEdit },

      { path: '/rates', name: 'rates', component: AdminRateList },
      { path: '/rates/create', name: 'rateCreate', component: AdminRateCreate },
      { path: '/rates/create/:id', name: 'rateUserCreate', component: AdminRateCreate },
      { path: '/rates/detail/:id', name: 'rateUserDetail', component: AdminRateDetail },
      { path: '/rates/edit/:id', name: 'rateEdit', component: AdminRateEdit },

      { path: '/rateranges', name: 'rateranges', component: AdminRaterangeList },
      { path: '/rateranges/create/:id', name: 'raterangeCreate', component: AdminRaterangeCreate },
      { path: '/rateranges/:id/edit', name: 'raterangeEdit', component: AdminRaterangeEdit },

      { path: '/orders', name: 'orders', component: AdminOrderList },
      { path: '/orderscustomreport', name: 'orderscustomerreport', component: AdminOrderSearchList },
      { path: '/orders/create', name: 'orderCreate', component: AdminOrderCreate },
      { path: '/orders/:id/edit', name: 'orderEdit', component: AdminOrderEdit },
      { path: '/orders/:id/editadvance', name: 'orderEditAdvance', component: AdminOrderAdvanceEdit },
      { path: '/orders/:id/log', name: 'orderloglist', component: AdminOrderLogList },
      



      { path: '/orderitems', name: 'orderitems', component: AdminOrderItemList },
      { path: '/orderitems/create', name: 'orderitemsCreate', component: AdminOrderItemCreate },
      { path: '/orderitems/:id/edit', name: 'orderitemsEdit', component: AdminOrderItemEdit },

      { path: '/riders', name: 'riders', component: AdminRiderList },
      { path: '/riders/create', name: 'riderCreate', component: AdminRiderCreate },
      { path: '/riders/:id/edit', name: 'riderEdit', component: AdminRiderEdit },
      { path: '/riders/:id/view', name: 'riderDetailView', component: AdminRiderDetailList },
      { path: '/riders/riderearningreport', name: 'riderearningreport', component: AdminRiderEarningSearchList },

      { path: '/drivers', name: 'drivers', component: AdminDriverList },
      { path: '/drivers/live', name: 'driversLive', component: AdminDriverListLive },
      { path: '/drivers/create', name: 'driverCreate', component: AdminDriverCreate },
      { path: '/drivers/:id/edit', name: 'driverEdit', component: AdminDriverEdit },
      { path: '/drivers/:id/view', name: 'driverDetailView', component: AdminDriverViewList },
      { path: '/drivers/:id/detail', name: 'driverDetailList', component: AdminDriverDetailList },
      { path: '/drivers/driverearningreport', name: 'driverearningreport', component: AdminDriverEarningSearchList },
      { path: '/drivers/loginlogs', name: 'driverloginlogs', component: AdminDriverLoginLogList },
      { path: '/drivers/driverearningreportall', name: 'driverearningreportall', component: AdminDriverEarningAllSearchList },



      { path: '/notifications', name: 'notifications', component: AdminNotificationList },
      { path: '/notifications/create', name: 'notificationCreate', component: AdminNotificationCreate },
      { path: '/notifications/:id/edit', name: 'notificationEdit', component: AdminNotificationEdit },
      { path: '/notifications/:id/view', name: 'notificationView', component: AdminNotificationEdit },


      { path: '/paymentmethods', name: 'paymentmethods', component: AdminPaymentmethodList },
      { path: '/paymentmethods/create', name: 'paymentmethodCreate', component: AdminPaymentmethodCreate },
      { path: '/paymentmethods/:id/edit', name: 'paymentmethodEdit', component: AdminPaymentmethodEdit },

      { path: '/cms', name: 'cms', component: AdminCmsList },
      { path: '/cms/create', name: 'cmsCreate', component: AdminCmsCreate },
      { path: '/cms/:id/edit', name: 'cmsEdit', component: AdminCmsEdit },

      { path: '/smslog', name: 'smslog', component: AdminSmsList },
      { path: '/orderpushs', name: 'orderpushs', component: AdminOrderpushList },


      { path: '/peakfactor', name: 'peakfactor', component: AdminPeakfactorList },
      { path: '/peakfactor/create', name: 'peakfactorCreate', component: AdminPeakfactorCreate },
      { path: '/peakfactor/create/:id', name: 'peakfactorCreate', component: AdminPeakfactorCreate },
      { path: '/peakfactor/:id/edit', name: 'peakfactorEdit', component: AdminPeakfactorEdit },

      { path: '/riderrates', name: 'riderrates', component: AdminRiderrateList },
      { path: '/riderrates/create', name: 'riderratesCreate', component: AdminRiderrateCreate },
      { path: '/riderrates/create/:id', name: 'riderratesUserCreate', component: AdminRiderrateCreate },
      { path: '/riderrates/detail/:id', name: 'riderratesUserDetail', component: AdminRiderrateDetail },
      { path: '/riderrates/edit/:id', name: 'riderratesEdit', component: AdminRiderrateEdit },

      { path: '/riderrateranges', name: 'riderrateranges', component: AdminRiderraterangeList },
      { path: '/riderrateranges/create/:id', name: 'riderraterangeCreate', component: AdminRiderraterangeCreate },
      { path: '/riderrateranges/:id/edit', name: 'riderraterangeEdit', component: AdminRiderraterangeEdit },

      { path: '/ordercancellists', name: 'ordercancellists', component: AdminOrderCancelList },



      { path: '/withdrawrequest', name: 'withdrawrequestList', component: AdminWithdrawRequestList },

      { path: '/withdrawrequest/:id/edit', name: 'withdrawrequestEdit', component: AdminWithdrawRequestEdit },

      { path: '/shifts', name: 'shifts', component: AdminShiftList },
      { path: '/shifts/create', name: 'shiftCreate', component: AdminShiftCreate },
      { path: '/shifts/:id/edit', name: 'shiftEdit', component: AdminShiftEdit },
      { path: '/shifts/:id/view', name: 'shiftView', component: AdminShiftView },

      { path: '/bookings', name: 'bookings', component: AdminBookingList },
      { path: '/bookings/create', name: 'bookingCreate', component: AdminBookingCreate },
      { path: '/bookings/:id/edit', name: 'bookingEdit', component: AdminBookingEdit },
      { path: '/bookings/:id/view', name: 'bookingView', component: AdminBookingDetail },


    ]
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
