
import { onMounted, reactive, ref  } from "vue";
import axios, { AxiosResponse } from "axios";
import * as c3 from "c3";
import Datepicker from "vue3-datepicker";
import moment from "moment";

export default {
  name: "AdminHourlyReport",
   components: {  datepicker: Datepicker },
  methods: {
    dateTime(value: any) {
      return moment(value).format("dddd DD-MMM-YYYY");
    },
  },
  setup() {
   const formdata = reactive({
      city_id:0,
      fromdate:new Date(),
      fromdate1:new Date(),
      fromdate2:new Date(),
      fromdate3:new Date(),
      fromdate4:new Date(),
      fromdate5:new Date(),
      fromdate6:new Date()
    });
    
   const cities =ref([]);
    const cityBasedCall = async () => {


      let fromdate = moment(formdata.fromdate).format("YYYY-MM-DD");
      formdata.fromdate=new Date(fromdate);
      const chartpk = c3.generate({
        bindto: "#charthourly1",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
             Count: "bar",
             Sales: "bar",
          },
        },
       
      });

      const { data } = await axios.post("orders/charthourly/"+formdata.city_id+"?fromdate="+fromdate);

      chartpk.load({
        columns: [
          ["X", ...data.map((r: any) => r.hour)],
          ["Sales", ...data.map((r: any) => r.sum)],
           ["Count", ...data.map((r: any) => r.count)],
        ],
      });
/////////////////////date object two///////////////////////////////////////

      let fromdate2 = moment(formdata.fromdate).subtract(1, 'days').format("YYYY-MM-DD");
          formdata.fromdate1=moment(formdata.fromdate).subtract(1, 'days').toDate();
      const chartpk2 = c3.generate({
        bindto: "#charthourly2",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
             Count: "bar",
             Sales: "bar",
          },
        },
       
      });

      // eslint-disable-next-line no-redeclare
      const dataobj = await axios.post("orders/charthourly/"+formdata.city_id+"?fromdate="+fromdate2);
     

      chartpk2.load({
        columns: [
          ["X", ...dataobj.data.map((r: any) => r.hour)],
          ["Sales", ...dataobj.data.map((r: any) => r.sum)],
           ["Count", ...dataobj.data.map((r: any) => r.count)],
        ],
      });
/////////////////////date object 3///////////////////////////////////////

      let fromdate3 = moment(formdata.fromdate).subtract(2, 'days').format("YYYY-MM-DD");
          formdata.fromdate2=moment(formdata.fromdate).subtract(2, 'days').toDate();
      const chartpk3 = c3.generate({
        bindto: "#charthourly3",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
             Count: "bar",
             Sales: "bar",
          },
        },
       
      });

      // eslint-disable-next-line no-redeclare
      const  dataobj3  = await axios.post("orders/charthourly/"+formdata.city_id+"?fromdate="+fromdate3);
      chartpk3.load({
        columns: [
          ["X", ...dataobj3.data.map((r: any) => r.hour)],
          ["Sales", ...dataobj3.data.map((r: any) => r.sum)],
           ["Count", ...dataobj3.data.map((r: any) => r.count)],
        ],
      });
/////////////////////date object 4///////////////////////////////////////

      let fromdate4 = moment(formdata.fromdate).subtract(3, 'days').format("YYYY-MM-DD");
          formdata.fromdate3=moment(formdata.fromdate).subtract(3, 'days').toDate();
      const chartpk4 = c3.generate({
        bindto: "#charthourly4",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
             Count: "bar",
             Sales: "bar",
          },
        },
       
      });

      // eslint-disable-next-line no-redeclare
      const    dataobj4  = await axios.post("orders/charthourly/"+formdata.city_id+"?fromdate="+fromdate4);
      chartpk4.load({
        columns: [
          ["X", ...dataobj4.data.map((r: any) => r.hour)],
          ["Sales", ...dataobj4.data.map((r: any) => r.sum)],
           ["Count", ...dataobj4.data.map((r: any) => r.count)],
        ],
      });

      /////////////////////date object 5///////////////////////////////////////

      let fromdate5 = moment(formdata.fromdate).subtract(4, 'days').format("YYYY-MM-DD");
          formdata.fromdate4=moment(formdata.fromdate).subtract(4, 'days').toDate();
      const chartpk5 = c3.generate({
        bindto: "#charthourly5",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
             Count: "bar",
             Sales: "bar",
          },
        },
       
      });

      // eslint-disable-next-line no-redeclare
      const  dataobj5  = await axios.post("orders/charthourly/"+formdata.city_id+"?fromdate="+fromdate5);
      chartpk5.load({
        columns: [
          ["X", ...dataobj5.data.map((r: any) => r.hour)],
          ["Sales", ...dataobj5.data.map((r: any) => r.sum)],
           ["Count", ...dataobj5.data.map((r: any) => r.count)],
        ],
      });


      /////////////////////date object 6///////////////////////////////////////

      let fromdate6 = moment(formdata.fromdate).subtract(5, 'days').format("YYYY-MM-DD");
          formdata.fromdate5=moment(formdata.fromdate).subtract(5, 'days').toDate();
      const chartpk6 = c3.generate({
        bindto: "#charthourly6",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
             Count: "bar",
             Sales: "bar",
          },
        },
       
      });

      // eslint-disable-next-line no-redeclare
      const  dataobj6  = await axios.post("orders/charthourly/"+formdata.city_id+"?fromdate="+fromdate6);
      chartpk6.load({
        columns: [
          ["X", ...dataobj6.data.map((r: any) => r.hour)],
          ["Sales", ...dataobj6.data.map((r: any) => r.sum)],
           ["Count", ...dataobj6.data.map((r: any) => r.count)],
        ],
      });

/////////////////////date object 7///////////////////////////////////////

      let fromdate7 = moment(formdata.fromdate).subtract(6, 'days').format("YYYY-MM-DD");
          formdata.fromdate6=moment(formdata.fromdate).subtract(6, 'days').toDate();
      const chartpk7 = c3.generate({
        bindto: "#charthourly7",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
             Count: "bar",
             Sales: "bar",
          },
        },
       
      });

      // eslint-disable-next-line no-redeclare
      const  dataobj7   = await axios.post("orders/charthourly/"+formdata.city_id+"?fromdate="+fromdate7);
      chartpk7.load({
        columns: [
          ["X", ...dataobj7.data.map((r: any) => r.hour)],
          ["Sales", ...dataobj7.data.map((r: any) => r.sum)],
           ["Count", ...dataobj7.data.map((r: any) => r.count)],
        ],
      });


    }

    onMounted(async () => {
        const  citiesResponse = await axios.get('cities/user/countryall'); 
      cities.value = citiesResponse.data;
      
cityBasedCall();
   
     
    });

  return {
      formdata,
       cities,
       cityBasedCall
    };

  }
}
