
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminRaterangeCreate",
 setup() {
    const data  =reactive({
      start_range:'',
      end_range:'',
      range_price:'',
      rate_id:'',  
      rate_price_type:'Fixed' 
    });
   
      const router = useRouter();
      const route = useRoute();
     const cities =ref([]);
 

    onMounted(async ()=>{
      
     if(route.params.id){
      data.rate_id = String(route.params.id);
      }
           
        
    });

const submit =async ()=>{

await axios.post(`/rateranges`,data);

  await router.push(`/rates/detail/${data.rate_id}`);
}


    return {
        data,
        
      submit
    };
  },
};
