
import { onMounted, ref } from "vue";
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminSmsList",
  components: { VPagination },
  methods: {
    dateTime(value: any) {
      return moment(value).format("YYYY-MM-DD h:mm:ss a");
    },
  },
  setup() {
    const smslist = ref([]);

    const lastPage = ref(0); //new comments

    const load = async (page = 1) => {
      const { data } = await axios.get(`sms?take=50&page=${page}`);
      smslist.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    return {
      smslist,
      lastPage,
      load,
    };
  },
};
