
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminPaymentmethodCreate",
 setup() {
    const data  =reactive({
         name:'',
        api_key:'',
        api_secret:'',
        api_password:'',
        is_show:'',
        is_external_call:'',
        country_id:'',
      key_name:'' 

    });
   
      const router = useRouter();
      const route = useRoute();
     const countries =ref([]);

   
    onMounted(async ()=>{
      const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
       

    });



const submit =async ()=>{

await axios.post(`/paymentmethods`,data);

await router.push('/paymentmethods');
}


    return {
        data,
        countries,
      submit
    };
  },
};
