
import { onMounted, ref } from "vue";
import axios from "axios";
import { Rate } from "@/models/rate";
import { Raterange } from "@/models/raterange";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminRiderrateList",
  components: { VPagination },
  setup() {
    const rates = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(`rates?page=${page}&take=50`);
      rates.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);
    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`riderrates/${id}`);

        rates.value = rates.value.filter((u: Rate) => u.id !== id);
      }
    };
    const delraterange = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`riderrateranges/${id}`);

        rates.value = rates.value.filter((u: Rate) => u.rateranges.id !== id);
      }
    };

    return {
      rates,
      lastPage,
      del,
      delraterange,
      load,
      selected,
      select,
    };
  },
};
