
import { onMounted, ref } from "vue";
import axios from "axios";
import { Country } from "@/models/country";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminPackageList",
  components: { VPagination },
  setup() {
    const countries = ref([]);

    const lastPage = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `countries/admin/list?page=${page}&take=50`
      );
      countries.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`countries/${id}`);

        countries.value = countries.value.filter((u: Country) => u.id !== id);
      }
    };

    return {
      countries,
      lastPage,
      del,
      load,
    };
  },
};
