
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
  name: "AdminShiftEdit",
  setup() {
    const form  =reactive({
      allow_rider:'',
      shift_fee:'',
      start_time:'',
      end_time:'',
      shift_date:'',
      city:''
    });
   
      const router = useRouter();
      const route = useRoute();
     const roles =ref([]);
   const cities =ref([]);

    onMounted(async ()=>{
      const  rolesResponse = await axios.get('roles');
      roles.value = rolesResponse.data;

        const  countryResponse = await axios.get('cities/user/countryall');
        cities.value = countryResponse.data;

      const response = await axios.get(`/shifts/${route.params.id}`)
      form.shift_fee =response.data.shift_fee;
      form.start_time =response.data.start_time;
      form.end_time =response.data.end_time;
      form.shift_date =response.data.shift_date;
      form.city =response.data.city.name;
      form.allow_rider=response.data.allow_rider;
      
    });

      const submit =async ()=>{

      await axios.put(`/shifts/${route.params.id}`,form);

      await router.push('/shifts');
      }


    return {
      form,
        roles,
        cities,
      submit
    };
  },
};
