
import {  reactive } from "vue";
import axios from "axios";
import {   useRouter } from "vue-router";
export default {
  name: "AdminForceupdateCreate",
  setup() {
    const data = reactive({
      device_type: "",
      version_code: "",
      version_name: "",
      is_force_update: "",
       app_type:"",
      is_db_schema_change: "",
    });

    const router = useRouter();

    const submit = async () => {
      await axios.post(`/forceupdate`, data);

      await router.push("/forceupdate");
    };

    return {
      data,

      submit,
    };
  },
};
