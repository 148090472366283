import axios from 'axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//const token = localStorage.getItem('jwt');
//if (token) {//
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//}

//'http://localhost:3005/api/';



axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = process.env.VUE_APP_APP_BASE_API_URL;
axios.defaults.withCredentials = true;

createApp(App).use(store).use(router).mount('#app')
