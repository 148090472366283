
import { onMounted, ref } from "vue";
import axios from "axios";
import moment from "moment"; 
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminBookingDetail",
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const booking = ref([]);

    const selected = ref(0);
 

   const route = useRoute();
 

    const bookinglistApi = async () => {
      const { data } = await axios.get(`/booking/${route.params.id}`);
      booking.value = data;
    };

 
    onMounted(bookinglistApi); 
 
 

 

    return {
      booking,
     
      selected,
     
 
     
    };
  },
};
