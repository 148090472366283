
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { GoogleMap, Marker } from "vue3-google-map";

export default {
  name: "AdminOrderTracking",
  components: { GoogleMap, Marker },
  methods: {
    doMath(key: number) {
      return key + 1;
    },
  },
  setup() {
    const data = reactive({
      orderstatus_id: "",
      cancel_note: "",
      courier_note: "",
      rider_id: "",
    });


    const route = useRoute();
    const orderstatuses = ref([]);
    const rider = ref([]);
    const order = ref([]);
    const isExpire = ref(0);

    onMounted(async () => {
      const statusResponse = await axios.get("orderstatus");
      orderstatuses.value = statusResponse.data;

      const response = await axios.get(`/orders/${route.params.id}`);
      order.value = response.data;
      data.cancel_note = response.data.cancel_note;
      data.courier_note = response.data.courier_note;
      data.rider_id = response.data.rider_id;
      if (
        response.data.orderstatus?.id == 2 ||
        response.data.orderstatus?.id == 3 ||
        response.data.orderstatus?.id == 11
      ) {
        isExpire.value = 1;
      }

      if (data.rider_id) {
        const responserider = await axios.get(`/riders/${data.rider_id}`);
        rider.value = responserider.data;
      }
    });

    return {
      order,

      data,
      orderstatuses,
      rider,
      isExpire,
    };
  },
};
