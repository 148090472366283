
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminConfigurationCreate",
  setup() {
    const data = reactive({
      base_url: "",
      static_url: "",
      is_force_update: "",
      version_code: "",
      version_name: "",
      is_maintenance: "",
      is_fb_login: "",
      is_gmail_login: "",
      is_apple_login: "",
      is_phone_verification: "",
      is_email_verification: "",
      about_us_url: "",
      tnc_url: "",
      faq_url: "",
      privacypolicy_url: "",
      country_id: "",
    });

    const router = useRouter();
    const countries = ref([]);

    onMounted(async () => {
      const countryResponse = await axios.get("countries");
      countries.value = countryResponse.data.data;
    });

    const submit = async () => {
      await axios.post(`/configurations`, data);

      await router.push("/configurations");
    };

    return {
      data,
      countries,
      submit,
    };
  },
};
