
import { onMounted, ref } from "vue";
import axios from "axios";
import { Configuration } from "@/models/configuration";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminForceupdateList",
  components: { VPagination },
  setup() {
    const forceupdates = ref([]);

    const lastPage = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(`forceupdate?page=${page}`);
      forceupdates.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`forceupdate/${id}`);

        forceupdates.value = forceupdates.value.filter(
          (u: Configuration) => u.id !== id
        );
      }
    };

    return {
      forceupdates,
      lastPage,
      del,
      load,
    };
  },
};
