
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminRateEdit",
 setup() {
    const data  =reactive({
        distance_type:'',
        per_unit_price:'',
        base_price:'',
        base_unit:'',
        is_base_rate:'',
        is_reference_rate:'',
        is_advance_rate:'',
        second_package_price:'',
        third_package_price:'',
        fourth_package_price:'',
        city_id:'',
        
        user_id:'',
       

    });
   
      const router = useRouter();
      const route = useRoute();
     const cities =ref([]);

    onMounted(async ()=>{
      const  citiesResponse = await axios.get('cities/all/list');
      cities.value = citiesResponse.data;
      const response = await axios.get(`/rates/${route.params.id}`);
      
        data.distance_type =response.data.distance_type;
        data.per_unit_price =response.data.per_unit_price;
        data.base_unit =response.data.base_unit;
        data.base_price =response.data.base_price;
        data.is_base_rate =response.data.is_base_rate;
        data.is_reference_rate =response.data.is_reference_rate;
        data.is_advance_rate =response.data.is_advance_rate;
        data.second_package_price =response.data.second_package_price;
        data.third_package_price =response.data.third_package_price;
        data.fourth_package_price =response.data.fourth_package_price;
        data.city_id =response.data.city?.id;
        data.user_id =response.data.user?.id;
       
     


    });

const submit =async ()=>{

await axios.put(`/rates/${route.params.id}`,data);

await router.push('/rates');
}


    return {
        data,
        cities,
      submit
    };
  },
};
