
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import {  useRouter } from 'vue-router';
export default {
  name: "AdminUserCreate",
  setup() {
    const form  = reactive({
      full_name:'',
      email:'',
      bussiness_name:'',
      phone:'',
      account_type:'',
      password:'',
      password_confirm :'',
      role_id:'',
      device_type:'Web',
      registration_type:'Manual',
      login_type:'Manual',
      country_id:''

    });
      const countries =ref([]);
      const router = useRouter();
     const roles =ref([]);

    onMounted(async ()=>{
      const {data} = await axios.get('roles');
      roles.value = data;
  const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
    });

const submit =async ()=>{

await axios.post('users',form);

await router.push('/users');
}


    return {
        form,
        roles,
         countries,
      submit
    } 
  } 
} 
