
import { onMounted, ref } from "vue";
import axios from "axios";
import { City } from "@/models/city";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminCityList",
  components: { VPagination },
  setup() {
    const cities = ref([]);

    const lastPage = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `cities/admin/list?page=${page}&take=50`
      );
      cities.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`cities/${id}`);

        cities.value = cities.value.filter((u: City) => u.id !== id);
      }
    };

    return {
      cities,
      lastPage,
      del,
      load,
    };
  },
};
