
import { computed, ref, watch, reactive } from "vue";

import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
export default {
  name: "MenuBar",
  props: {
    isshowsidebar: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const toogle = reactive({
      showMenu: true,
      showSetting: false,
      showReport: false,
      showLog: false,
    });

    const sidebarsetting = async () => {
      toogle.showSetting = !toogle.showSetting;
      console.log(toogle.showSetting);
    };

    
    const reportsetting = async () => {
      toogle.showReport = !toogle.showReport;
     
    };
      const logsetting = async () => {
      toogle.showLog = !toogle.showLog;
      
    };
    const name = ref("");

    const store = useStore();
    const user = computed(() => store.state.User.user);

    watch(user, () => {
      name.value = user.value.full_name;
    });

    const logout = async () => {
      await axios.post("logout");
    };

    return {
      sidebarsetting,
      reportsetting,
      logsetting,
      toogle,
      name,
      logout,
    };
  },
};
