
import { onMounted, ref } from "vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "AdminDashBoard",

  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const liveorders = ref([]);
    const scheduleorders = ref([]);
    const deliveredorders = ref([]);
    const cancelorders = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const ongoingtotal = ref(0);
    const scheduletotal = ref(0);
    const completedtotal = ref(0);
    const canceltotal = ref(0);
    const activetab = ref("liveorders"); //liveorders

    const load = async (page = 1) => {
      const { data } = await axios.get(`orders/admin/ongoing?page=${page}`);
      liveorders.value = data.data;
      lastPage.value = data.meta.last_page;
      ongoingtotal.value = data.meta.total;
    };

    const loadschedule = async (page = 1) => {
      const { data } = await axios.get(`orders/admin/schedule?page=${page}`);
      scheduleorders.value = data.data;
      lastPage.value = data.meta.last_page;
      scheduletotal.value = data.meta.total;
    };

    const loadcompleted = async (page = 1) => {
      const { data } = await axios.get(`orders/admin/completed?page=${page}`);
      deliveredorders.value = data.data;
      lastPage.value = data.meta.last_page;
      completedtotal.value = data.meta.total;
    };

    const loadcanceled = async (page = 1) => {
      const { data } = await axios.get(`orders/admin/canceled?page=${page}`);
      cancelorders.value = data.data;
      lastPage.value = data.meta.last_page;
      canceltotal.value = data.meta.total;
    };

    onMounted(load);
    onMounted(loadschedule);
    onMounted(loadcompleted);
    onMounted(loadcanceled);

    setInterval(function () {
      load();
    }, 120000);

    setInterval(function () {
      loadschedule();
    }, 360000);
    setInterval(function () {
      loadcompleted();
    }, 480000);

    setInterval(function () {
      loadcanceled();
    }, 800000);

    const select = async (id: number) => {
      selected.value = selected.value !== id ? id : 0;
      
    };

    const btnselecttab = async (tabname: string) => {
      activetab.value = tabname;
    };

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`orders/${id}`);

        //  orders.value = orders.value.filter((u: Order) => u.id !== id);
      }
    };

    return {
      liveorders,
      scheduleorders,
      deliveredorders,
      cancelorders,
      lastPage,
      del,

      load,
      selected,
      select,
      btnselecttab,
      ongoingtotal,
      scheduletotal,
      completedtotal,
      canceltotal,
      activetab,
    };
  },
};
