
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminConfigurationEdit",
 setup() {
    const data  =reactive({
        base_url:'',
        static_url:'',
        is_force_update:'',
        version_code:'',
        version_name:'',
        is_maintenance:'',
        is_fb_login:'',
        is_gmail_login:'',
        is_apple_login:'',
        is_phone_verification:'',
        is_email_verification:'',
        about_us_url:'',
        tnc_url:'',
        faq_url:'',
        privacypolicy_url:'',
        country_id:'',

    });
   
      const router = useRouter();
      const route = useRoute();
     const countries =ref([]);

    onMounted(async ()=>{
      const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
      const response = await axios.get(`/configurations/${route.params.id}`);
      
        data.base_url =response.data.base_url;
        data.static_url =response.data.static_url;
        data.is_force_update =response.data.is_force_update;
        data.version_code =response.data.version_code;
        data.version_name =response.data.version_name;
        data.is_maintenance =response.data.is_maintenance;
        data.is_fb_login =response.data.is_fb_login;
        data.is_gmail_login =response.data.is_gmail_login;
        data.is_apple_login =response.data.is_apple_login;
        data.is_phone_verification =response.data.is_phone_verification;
        data.is_email_verification =response.data.is_email_verification;
        data.about_us_url =response.data.about_us_url;
        data.tnc_url =response.data.tnc_url;
        data.faq_url =response.data.faq_url;
        data.privacypolicy_url =response.data.privacypolicy_url;
        data.country_id =response.data.country?.id;
     



    });

const submit =async ()=>{

await axios.put(`/configurations/${route.params.id}`,data);

await router.push('/configurations');
}


    return {
        data,
        countries,
      submit
    };
  },
};
