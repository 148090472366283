
import { onMounted, reactive } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminDriverEdit",
  setup() {
    const data = reactive({
      rider_name: "",
      rider_email: "",
      rider_phone: "",
      is_buzy: 0,
      availability_status: 0,
      is_active:0,
      is_block:0,
      rider_dob:"",
      rider_registration_no:"",
      rider_postal_code:"",
   
      bank_account_title:"",
      bank_account_number:"",
      bank_sort_code:"",
      bank_other_detail:"",
    });

    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      const response = await axios.get(`/drivers/${route.params.id}`);

      data.rider_name = response.data.rider_name;
      data.rider_email = response.data.rider_email;
      data.rider_phone = response.data.rider_phone;
      data.is_buzy = response.data.is_buzy;
      data.availability_status = response.data.availability_status;
      data.is_active = response.data.is_active;
      data.is_block = response.data.is_block;
      data.rider_dob = response.data.rider_dob;
      data.rider_registration_no = response.data.rider_registration_no;
      data.rider_postal_code = response.data.rider_postal_code;
       
      data.bank_account_title = response.data.bank_account_title;
      data.bank_account_number = response.data.bank_account_number;
      data.bank_sort_code = response.data.bank_sort_code;
      data.bank_other_detail = response.data.bank_other_detail;
    });

    const submit = async () => {
      await axios.put(`/drivers/${route.params.id}`, data);

      await router.push("/drivers");
    };

    return {
      data,

      submit,
    };
  },
};
