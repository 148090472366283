
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute,useRouter } from 'vue-router';
import {Permission} from '@/models/permission'
export default {
  name: "AdminRoleEdit",
  setup() {
    const formData  =reactive({
      name:'',
      permissions:[] as number[]
     

    });
   
      const router = useRouter();
       const route = useRoute();
      const permissionList=ref([]);
    onMounted(async ()=>{
      const {data} = await axios.get('permissions');
      permissionList.value= data;

      const response =await axios.get(`roles/${route.params.id}`);
        formData.name=response.data.name;
        formData.permissions=response.data.permissions.map((p: Permission)=>p.id);
    });

const submit =async ()=>{

await axios.put(`roles/${route.params.id}`,formData);

await router.push('/roles');
}

const checked = (id: number)=>{

return formData.permissions.some(p=> p ===id);
}



const select =(id: number, checkecd:boolean)=>{
      if(checkecd){
         formData.permissions=[...formData.permissions, id];
         return;
      }
 formData.permissions= formData.permissions.filter(p => p !==id);

}


    return {
        formData,
        permissionList,
        select,
        checked,
      submit
    };
  },
};
