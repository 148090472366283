
import { onMounted, reactive } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminCountryEdit",
 setup() {
    const data  =reactive({
        name:'',
        country_code:'',
        currency_short_name:'',
        curreny_standard_name:'',
        country_time_zone:'',
        country_topic:'',
        is_show:'',
         distance_type:'',
         country_ios_code:''
    });
   
      const router = useRouter();
      const route = useRoute();
   

    onMounted(async ()=>{
      
      const response = await axios.get(`/countries/${route.params.id}`);
     
    data.name =response.data.name;
    data.country_code =response.data.country_code;
    data.currency_short_name =response.data.currency_short_name;
    data.curreny_standard_name =response.data.curreny_standard_name;
    data.country_time_zone =response.data.country_time_zone;
    data.country_topic =response.data.country_topic;
    data.is_show =response.data.is_show;
    data.distance_type=response.data.distance_type


    });

const submit =async ()=>{

await axios.put(`/countries/${route.params.id}`,data);

await router.push('/countries');
}


    return {
        data,
     
      submit
    };
  },
};
