
import { onMounted, ref } from "vue";
import axios from "axios";
import { Order } from "@/models/order";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "AdminOrderList",
  components: { VPagination },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const orders = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const search = ref("");
    const load = async (page = 1) => {
      const { data } = await axios.get(
        `orders?page=${page}&take=50&search=${search.value}`
      );
      orders.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const select = async (id: number) => {
      selected.value = selected.value !== id ? id : 0;
     
    };

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`orders/${id}`);

        orders.value = orders.value.filter((u: Order) => u.id !== id);
      }
    };
    const delraterange = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`rateranges/${id}`);
      }
    };

    const exportCSV = async () => {
      const { data } = await axios.post(
        "orders/export",
        {},
        { responseType: "blob" }
      );
      const blob = new Blob([data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(data);
      link.download = "orders.csv";
      link.click();
    };

    const searchcall = async () => {
      load();
    };
    const searchclear = async () => {
      search.value = "";

      load();
    };

    return {
      orders,
      lastPage,
      search,
      del,
      delraterange,
      load,
      selected,
      select,
      exportCSV,
      searchcall,
      searchclear,
    };
  },
};
