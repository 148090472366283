
import { onMounted } from "vue";
import axios from "axios";
import * as c3 from "c3";
export default {
  name: "AdminDashBoard",
  setup() {
    onMounted(async () => {
      const chartpk = c3.generate({
        bindto: "#chartpk",
        data: {
          x: "X",
          columns: [["X"], ["Sales"]],
          types: {
            Sales: "bar",
          },
        },
        axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%Y-%m-%d",
            },
          },
        },
      });

      const { data } = await axios.post("orders/chart/1");
      chartpk.load({
        columns: [
          ["X", ...data.map((r: any) => r.date)],
          ["Sales", ...data.map((r: any) => r.sum)],
        ],
      });
      const chartuk = c3.generate({
        bindto: "#chartuk",
        data: {
          x: "X",
          columns: [["X",'2020-2-2'], ["Sales",100]],
          types: {
            Sales: "bar",
          },
        },
        axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%Y-%m-%d",
            },
          },
        },
      });

      const response = await axios.post("orders/chart/2");
     // const { ukdata } = response.data;
      chartuk.load({
        columns: [
          ["X", ...response.data.map((r: any) => r.date)],
          ["Sales", ...response.data.map((r: any) => r.sum)],
        ],
      });
    });
  }
}
