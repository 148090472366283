
import { onMounted, ref } from "vue";
import axios from "axios";
import { Rider } from "@/models/rider";
import { Raterange } from "@/models/raterange";
import moment from "moment";
import AdminPagination from "@/components/AdminPagination.vue";

import { useRoute, useRouter } from "vue-router";

export default {
  name: "AdminRiderDetailList",
  components: { AdminPagination },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const riderearnings = ref([]);
    const riderwallets = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const search = ref("");
    const rider = ref([]);
    const router = useRouter();
    const route = useRoute();

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `riderearnings/rider/${route.params.id}?page=${page}&take=50&search=${search.value}`
      );
      riderearnings.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const riderwallet = async (page = 1) => {
      const { data } = await axios.get(
        `riderwallet/rider/${route.params.id}?page=${page}&take=50&search=${search.value}`
      );
      riderwallets.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(riderwallet);

    const riderdata = async () => {
      const { data } = await axios.get(`riders/${route.params.id}`);
      rider.value = data;
    };

    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };

    onMounted(riderdata);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        //  await axios.delete(`riders/${id}`);

        riderearnings.value = riderearnings.value.filter(
          (u: Rider) => u.id !== id
        );
      }
    };

    const searchcall = async () => {
      load();
    };
    const searchclear = async () => {
      search.value = "";

      load();
    };
    return {
      riderearnings,
      lastPage,
      rider,
      del,
      search,
      load,
      selected,
      select,
      searchcall,
      searchclear,
      riderwallets,
    };
  },
};
