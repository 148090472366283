
import { onMounted, ref } from "vue";
import axios from "axios";
import moment from "moment";
import AdminPagination from "@/components/AdminPagination.vue";
import Datepicker from "vue3-datepicker";

export default {
  name: "AdminOrderSearchList",
  components: { AdminPagination, datepicker: Datepicker },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const orders = ref([]);
    const summary = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const rider = ref([]);
    const users = ref([]);

    const fromdate = ref(new Date());
    const todate = ref(new Date());
    const user_id = ref(0);

    const load = async () => {
      var custodate = moment(todate.value).format("YYYY-MM-DD");
      var cusfromdate = moment(fromdate.value).format("YYYY-MM-DD");
      const { data } = await axios.get(
        `orders/customreport/user/${user_id.value}?todate=${custodate}&fromdate=${cusfromdate}`
      );
      orders.value = data.data.data;
      summary.value = data.summary;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const loadusers = async () => {
      const { data } = await axios.get(`users/postpaid`);
      users.value = data;
    };

    onMounted(loadusers);

    const downloadpdf = async () => {
      var custodate = moment(todate.value).format("YYYY-MM-DD");
      var cusfromdate = moment(fromdate.value).format("YYYY-MM-DD");
      const { data } = await axios.get(
        `orders/pdf/${user_id.value}/generate?todate=${custodate}&fromdate=${cusfromdate}`,
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "test.pdf";
      link.click();
    };

    const searchcall = async () => {
      load();
    };

    return {
      orders,
      lastPage,

      summary,
      load,
      selected,

      downloadpdf,
      rider,
      searchcall,
      user_id,
      fromdate,
      todate,
      users,
    };
  },
};
