
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
  name:"AdminRiderrateCreate",
 setup() {
    const data  =reactive({
        distance_type:'',
        per_unit_price:'',
        base_price:'',
        base_unit:'',
        is_base_rate:'',
        is_reference_rate:'',
        is_advance_rate:'',
        aditional_per_order_price:0,
        aditional_order_same_client: 0,
      aditional_order_diff_client: 0,
        city_id:0,
         

    });
   
      const router = useRouter();
      const route = useRoute();
    

    onMounted(async ()=>{
    
      if(route.params.id){
        var paramid = route.params.id;
        data.city_id =  Number(paramid);
      }
     

    });

const submit =async ()=>{

await axios.post(`/riderrates`,data);

  await router.push(`/cities/${route.params.id}/detail`);
}


    return {
        data,
       
      submit
    };
  },
};
