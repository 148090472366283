
import {  onMounted,ref, SetupContext, watch } from 'vue';
export default {
   name: 'AdminPagination',
   emits:['page-changed'],
   props:{
      lastPage: Number 
   } ,
   setup(props: { lastPage: number }, context: SetupContext){
   const page = ref(1);
     const pagesecond= ref(2);
     const pagethird = ref(3);
     const secondlast = ref(0);
     const lastPageNumber = ref(0);



     const loadlstpag = async () => {
       lastPageNumber.value = props.lastPage;
     }

     onMounted(loadlstpag);



        watch(page,()=>{
            context.emit('page-changed',page.value);
        });

    const next =   ()=>{
      if(page.value < props.lastPage){
        page.value++;
        if (page.value < props.lastPage) {
        pagesecond.value = page.value + 1; 
        }
      }
    }

     const selectedPage = (pagenumber:number) => {
      console.log(pagenumber);
       if (pagenumber < props.lastPage) {
         page.value = pagenumber;
         if (page.value < props.lastPage) {
    pagesecond.value = page.value+1;
         }
       }
     }

  const  previous =   ()=>{
     if(page.value>1){
      page.value--;
         if (page.value < props.lastPage) {
       pagesecond.value = page.value +1;
         }
     }
     }

     




return {
  page,
    next,
    previous,
  selectedPage,
  pagesecond,
  pagethird,
  lastPageNumber 
 
}

    
   } 
}
