import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = { class: "page-item" }
const _hoisted_3 = { class: "page-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("a", {
          class: "page-link",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.previous && $setup.previous(...args)))
        }, "Previous")
      ]),
      _createElementVNode("li", _hoisted_3, [
        _createElementVNode("a", {
          class: "page-link",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.next && $setup.next(...args)))
        }, "Next")
      ])
    ])
  ]))
}