import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = {
  key: 0,
  class: "col-md-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isExpire)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_GoogleMap, {
            "api-key": "AIzaSyAfV96tdeqK3NskgsrFNt3H2xUx-2LNOSk",
            style: {"width":"100%","height":"500px"},
            center: { lat: parseFloat($setup.rider.rider_lat), lng: parseFloat($setup.rider.rider_lan) },
            zoom: 17
          }, {
            default: _withCtx(() => [
              ($setup.rider.id)
                ? (_openBlock(), _createBlock(_component_Marker, {
                    key: $setup.rider.id,
                    options: { position: { lat: parseFloat($setup.rider.rider_lat), lng: parseFloat($setup.rider.rider_lan) },  label: 'R', title:'Rider '  	 }
                  }, null, 8, ["options"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["center"])
        ]))
      : _createCommentVNode("", true)
  ]))
}