

import menueBar from  "@/components/MenuBar.vue";
import { onMounted,reactive } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  name: "AdminLayout",
  
     components: {
     'app-menu-bar': menueBar
   
  },
  setup(){
const router = useRouter();
const store = useStore();

const toogle =reactive({
      showMenu:true,
      showSetting:false , 
      sidbarclass:''
    });

  const sidebarfunction =async ()=>{
   
     toogle.showMenu = !toogle.showMenu;

     if(!toogle.showMenu){
      toogle.sidbarclass='contentfull';  
     }else{
        toogle.sidbarclass='contentless';  
     }
       
 };

    onMounted(async ()=>{
      try{
      const {data} =await axios.get('adminuser');
      await store.dispatch('User/setUser',data)
     // console.log(data);

      } catch(e){
        await router.push('/login');
      }

    });

 return {
     sidebarfunction,
        toogle,
    }

  }
}
