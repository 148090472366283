
import { onMounted, reactive } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminWithdrawRequestEdit",
  setup() {
    const data = reactive({
      detail: "",
      is_approved: "",
      logs: "",
    });

    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      const response = await axios.get(`/withdrawrequest/${route.params.id}`);
      data.detail = response.data.detail;
      data.is_approved = response.data.is_approved;
      data.logs = response.data.logs;
    });

    const submit = async () => {
      await axios.put(`/withdrawrequest/${route.params.id}`, data);

      await router.push("/withdrawrequest");
    };

    return {
      data,

      submit,
    };
  },
};
