
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
  name: "AdminPeakfactorCreate",
  setup() {
    const data = reactive({
      day_name: '',
      peak_factor: '1',
      time_start: '0',
      time_end: '23',
      city_id: 0


    });

    const router = useRouter();
    const route = useRoute();


    onMounted(async () => {

     
      const response = await axios.get(`/peakfactor/${route.params.id}`);

      data.day_name = response.data.day_name;
      data.peak_factor = response.data.peak_factor;
      data.time_start = response.data.time_start;
      data.time_end = response.data.time_end;
      data.city_id = response.data.city.id;
  

    });

    const submit = async () => {

      await axios.put(`/peakfactor/${route.params.id}`, data);
      await router.push(`/cities/${data.city_id}/detail`);


    }

    return {
      data,
      submit
    };
  },
};
