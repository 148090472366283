
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import * as c3 from "c3";
import Datepicker from "vue3-datepicker";
 
import moment from "moment";
export default {
  name: "AdminDailyReport",
   components: {  datepicker: Datepicker },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {

    const formdata = reactive({
      city_id:0,
      fromdate:new Date()
    });
    
   const cities =ref([]);

    const cityBasedCall = async () => {
      let fromdate = moment(formdata.fromdate).format("YYYY-MM-DD");
      const chartpk = c3.generate({
        bindto: "#chartdaily",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
            Sales: "bar",
          },
        },
        axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%Y-%m-%d",
            },
          },
        },
      });

      const { data } = await axios.post("orders/chartdaily/"+formdata.city_id+"?fromdate="+fromdate);
      chartpk.load({
        columns: [
          ["X", ...data.map((r: any) => r.date)],
          ["Sales", ...data.map((r: any) => r.sum)],
           ["Count", ...data.map((r: any) => r.count)],
        ],
      });

    }


    onMounted(async () => {

      let fromdate = moment(formdata.fromdate).format("YYYY-MM-DD");
       const  citiesResponse = await axios.get('cities/user/countryall'); 
      cities.value = citiesResponse.data;

      const chartpk = c3.generate({
        bindto: "#chartdaily",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
            Sales: "bar",
          },
        },
        axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%Y-%m-%d",
            },
          },
        },
      });

      const { data } = await axios.post("orders/chartdaily/"+formdata.city_id +"?fromdate="+fromdate);
      chartpk.load({
        columns: [
          ["X", ...data.map((r: any) => r.date)],
          ["Sales", ...data.map((r: any) => r.sum)],
           ["Count", ...data.map((r: any) => r.count)],
        ],
      });
     
    });
      return {
      formdata,
       cities,
       cityBasedCall
    };
  }
}
