
import axios  from "axios";
import { SetupContext }  from 'vue';
export default {
    name:"AdminImageUpload",
    emits:['uploaded'],
    setup(props: any,context:SetupContext){
        const upload = async (files: FileList | null)=>{
            if (files === null) return;
           

            const formData = new FormData();
            formData.append('image',files[0]);
            const {data} = await axios.post('upload',formData);
            context.emit('uploaded',data.url);
        }

    return {
        upload
    }


    }
}
