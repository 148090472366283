
import {  onMounted ,reactive, ref} from "vue";
import axios from "axios";
import * as c3 from "c3";
 
export default {
  name: "AdminMonthlyReport",
  setup() {
 
 
 
    const formdata = reactive({
      city_id:0,
    });
    
   const cities =ref([]);

    const cityBasedCall = async (event: { target: { value: number; }; }) => {
      formdata.city_id = event.target.value;


      const { data } = await axios.post("orders/chartmonthly/"+formdata.city_id);
            const chartmontly = c3.generate({
        bindto: "#chartmontly",
        data: {
          x: "X",
          columns: [["X"], ["Sales"], ["Count"]],
          types: {
            Sales: "bar",
          },
        },
        axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%Y-%m",
            },
          },
        },
      });
      chartmontly.load({
        columns: [
          ["X", ...data.map((r: any) => r.month+"-01")],
         
         ["Sales", ...data.map((r: any) =>  r.sum   )],
          ["Count", ...data.map((r: any) =>  r.count   )],
        ],
      });

      // console.log(event.target.value)
    };



    onMounted(async () => { 

       const  citiesResponse = await axios.get('cities/user/countryall'); 
      cities.value = citiesResponse.data;

      const chartmontly = c3.generate({
        bindto: "#chartmontly",
        data: {
          x: "X",
          columns: [["X"],  ["Sales"], ["Count"]],
          types: {
            Sales: "bar",
              Count: "bar",
          },
        },
         axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%Y-%m",
            },
          },
        },
      });

      const { data } = await axios.post("orders/chartmonthly/"+formdata.city_id);
      chartmontly.load({
        columns: [
        ["X", ...data.map((r: any) => r.month+"-01")],
       
         ["Sales", ...data.map((r: any) =>  r.sum   )],
          ["Count", ...data.map((r: any) =>  r.count   )],
        ],
      });
     
    });

     return {
      formdata,
       cities,
       cityBasedCall
    };
  }
};
