
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminPeakfactorCreate",
 setup() {
    const data  =reactive({
      day_name:'',
      peak_factor:'1',
      time_start:'0',
      time_end:'23',
      city_id:0


    });
   
      const router = useRouter();
      const route = useRoute();
   

       onMounted(async ()=>{
    
      if(route.params.id){
        var paramid = route.params.id;
        data.city_id =  Number(paramid);
      }
     

    });

const submit =async ()=>{

await axios.post(`/peakfactor`,data);
await router.push(`/cities/${route.params.id}/detail`);


}

    return {
        data,
        submit
    };
  },
};
