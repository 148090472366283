
import { onMounted, ref, watch } from 'vue';
import   axios   from 'axios';
import {Role} from '@/models/role'
export default {
    name:"AdminRoleList",
    setup(){
      const roles = ref([]);
      const page = ref(1);
      const lastPage = ref(0);


  const load = async ()=>{
   const {data} = await  axios.get(`roles?page=${page.value}`);
          roles.value =data;
        lastPage.value=data.meta.last_page;   
    }

      onMounted(load);

      watch(page,load);

    const next =   ()=>{
      if(page.value < lastPage.value){
        page.value++;
        
      }
    }

  const  previous =   ()=>{
     if(page.value>1){
      page.value--;
    
     }
    }

const del =async (id:number)=>{
  if(confirm('Are You Sure?')){
    await axios.delete(`roles/${id}`);

    roles.value = roles.value.filter((u: Role)=> u.id !== id);

  }

}


      return {
        roles,
        next,
        previous,
        del
      }
      
    }    
}
