
import { onMounted, ref } from 'vue';
import   axios   from 'axios';
import {City} from '@/models/city'
import  AdminPagination from '@/components/AdminPagination.vue'
export default {
  name:"AdminCityList",
  components: {AdminPagination} ,
  setup(){
      const cities = ref([]);
   
      const lastPage = ref(0);


  const load = async (page=1)=>{
   const {data} = await  axios.get(`cities?page=${page}`);
          cities.value =data.data;
        lastPage.value=data.meta.last_page;   
    }

      onMounted(load);

     

const del =async (id:number)=>{
  if(confirm('Are You Sure?')){
    await axios.delete(`cities/${id}`);

    cities.value = cities.value.filter((u: City)=> u.id !== id);

  }

}


      return {
        cities,
      lastPage,
        del,
        load
      }
      
    }    
}
