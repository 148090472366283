
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminRiderEdit",
  setup() {
    const data = reactive({
      rider_name: "",
      rider_email: "",
      rider_mobile_no: "",
      is_buzy: "",
      availability_status: "",
      status: "",
    });

    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      const response = await axios.get(`/riders/${route.params.id}`);

      data.rider_name = response.data.rider_name;
      data.rider_email = response.data.rider_email;
      data.rider_mobile_no = response.data.rider_mobile_no;
      data.is_buzy = response.data.is_buzy;
      data.availability_status = response.data.availability_status;
      data.status = response.data.status;
    });

    const submit = async () => {
      await axios.put(`/riders/${route.params.id}`, data);

      await router.push("/riders");
    };

    return {
      data,

      submit,
    };
  },
};
