
  import { onMounted, reactive, ref } from "vue";
  import axios from "axios";
  import { useRoute } from "vue-router";
  import { GoogleMap, Marker } from "vue3-google-map";

  export default {
    name: "AdminRiderTracking",
    components: { GoogleMap, Marker },
    methods: {
      doMath(key: number) {
        return key + 1;
      },
    },
    setup() {
      const data = reactive({
        orderstatus_id: "",
        cancel_note: "",
        courier_note: "",
        rider_id: "",
      });

 
      const route = useRoute();
       const rider = ref([]);
 

      const load = async () => {

        const responserider = await axios.get(`/drivers/${route.params.id}`);
        rider.value = responserider.data;

      };


      onMounted(load);
      setInterval(function () {
        load();
      }, 12000);



      return {


        data,

        rider,

      };
    },
  };
