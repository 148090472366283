
import { onMounted, reactive } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminOrderItemEdit",
 setup() {
    const data  =reactive({
        is_delivered:'',
        courier_note:'',
        customer_email:'',
        customer_phone:'',
         customer_name:''

    });
   
      const router = useRouter();
      const route = useRoute();
    
  

    onMounted(async ()=>{
     
      const response = await axios.get(`/orderitems/${route.params.id}`);
      
      data.is_delivered =response.data.is_delivered;
      data.courier_note =response.data.courier_note;
      data.customer_email =response.data.customer_email;
      data.customer_phone= response.data.customer_phone;
        data.customer_name= response.data.customer_name;
      
      

    });

const submit =async ()=>{

await axios.put(`/orderitems/${route.params.id}`,data);

await router.push('/orders');
}


    return {
        data,
        
      submit
    };
  },
};
