
import { onMounted, ref } from "vue";
import axios from "axios";
import { Driverloginlog } from "@/models/driverloginlog";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminDriverLoginLogList",
  components: { VPagination },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const driverloginlogs = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(
        `driverloginlogs?page=${page}&take=100`
      );
      driverloginlogs.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);
    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`order-cancel-log/${id}`);

        driverloginlogs.value = driverloginlogs.value.filter(
          (u: Driverloginlog) => u.id !== id
        );
      }
    };

    return {
      driverloginlogs,
      lastPage,
      del,

      load,
      selected,
      select,
    };
  },
};
