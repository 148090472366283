
import { computed,  reactive, onMounted, watch, ref } from "vue";
import axios  from "axios";
import { useStore } from 'vuex';
export default {
    name:"AdminProfile",
    setup() {
        const infoData = reactive({
           full_name:'',
           phone:'', 
           email:'', 
            country_id:'', 
            
        });
const btnData = reactive({

            showsaveinfobtn:false,
             showsavepasswordbtn:false,
              showtopmessage:false,
             message:"test test"
        });
        const passwordData = reactive({
           password:'',
           password_confirm:''  
        });

   const store = useStore();
   const user =computed(()=>store.state.User.user);
   const countries =ref([]);
  onMounted(async ()=>{
  const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;


  infoData.full_name=user.value.full_name;
            infoData.phone=user.value.phone;
            infoData.email=user.value.email;
               infoData.country_id=user.value.country.id;
  });
        watch(user,()=>{

            infoData.full_name=user.value.full_name;
            infoData.phone=user.value.phone;
            infoData.email=user.value.email;
              infoData.country_id=user.value.country.id;
        });

        const infoSubmit = async ()=>{
              btnData.showsaveinfobtn=true;
           const {data} = await axios.put('users/info',infoData);
           await store.dispatch('User/setUser',data);
            btnData.showtopmessage=true;
           
           btnData.message="Profile info is updated";
             btnData.showsaveinfobtn=false;

        }
          const passwordSubmit =async ()=>{
                btnData.showsavepasswordbtn=true;
             await axios.put('users/password',passwordData)
                  btnData.showtopmessage=true;
             btnData.message="Password is changed";
              btnData.showsavepasswordbtn=false;

        }

        return {
            infoData,
            passwordData,
            infoSubmit,
            passwordSubmit,
            countries,
            btnData
        }

    }
        
}
