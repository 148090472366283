
import { onMounted, ref } from "vue";
import axios from "axios";
import { Order } from "@/models/order";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "AdminOrderItemList",
  components: { VPagination },
  methods: {
    dateTime(value: any) {
      return moment(value).format("DD-MMM-YYYY HH:mm");
    },
  },
  setup() {
    const orderpushs = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);
    const rider = ref([]);

    const load = async (page = 1) => {
      const { data } = await axios.get(`orderpush?page=${page}&take=50`);
      orderpushs.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const select = async (id: number, rider_id: any) => {
      selected.value = selected.value !== id ? id : 0;
      if (rider_id) {
        const { data } = await axios.get(`riders/${rider_id}`);
        rider.value = data;
      }
    };

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`orderpush/${id}`);

        orderpushs.value = orderpushs.value.filter((u: Order) => u.id !== id);
      }
    };

    return {
      orderpushs,
      lastPage,
      del,
      load,
      selected,
      select,
      rider,
    };
  },
};
