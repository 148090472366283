
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminOrderItemCreate",
 setup() {
    const data  =reactive({
        distance_type:'',
        per_unit_price:'',
        base_price:'',
        base_unit:'',
        is_base_rate:'',
        is_reference_rate:'',
        is_advance_rate:'',
        city_id:'',
        
        user_id:'',
       

    });
   
      const router = useRouter();
      const route = useRoute();
     const cities =ref([]);

    onMounted(async ()=>{
      const  citiesResponse = await axios.get('cities/all/list');
      cities.value = citiesResponse.data;
      if(route.params.id){
      data.user_id = String(route.params.id);
      }
     

    });

const submit =async ()=>{

await axios.post(`/rates`,data);

await router.push('/rates');
}


    return {
        data,
        cities,
      submit
    };
  },
};
