
import { onMounted, ref } from "vue";
import axios from "axios";
import { Device } from "@/models/device";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminDeviceList",
  components: { VPagination },
  setup() {
    const devices = ref([]);

    const lastPage = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(`devices?page=${page}`);
      devices.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`devices/${id}`);

        devices.value = devices.value.filter((u: Device) => u.id !== id);
      }
    };

    return {
      devices,
      lastPage,
      del,
      load,
    };
  },
};
