
import { onMounted, ref } from "vue";
import axios from "axios";
import { Rate } from "@/models/rate";
import { Raterange } from "@/models/raterange";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminRiderrateDetail",
  setup() {
    const riderrate = ref([]);
    const lastPage = ref(0);
    const selected = ref(0);

    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      const { data } = await axios.get(`/riderrates/${route.params.id}`);
      riderrate.value = data;
    });

    const select = (id: number) => {
      selected.value = selected.value !== id ? id : 0;
    };
    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`riderrates/${id}`);

        riderrate.value = riderrate.value.filter((u: Rate) => u.id !== id);
      }
    };
    const delraterange = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`riderrateranges/${id}`);
        await router.push(`/riderrates/detail/${route.params.id}`);
      }
    };
    return {
      riderrate,
      del,
      delraterange,
      selected,
      select,
    };
  },
};
