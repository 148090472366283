
import { onMounted, ref } from "vue";
import axios from "axios";
import { Cms } from "@/models/cms";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminCmsList",
  components: { VPagination },
  setup() {
    const cmses = ref([]);

    const lastPage = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(`cms`);
      cmses.value = data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        await axios.delete(`cms/${id}`);

        cmses.value = cmses.value.filter((u: Cms) => u.id !== id);
      }
    };

    return {
      cmses,
      lastPage,
      del,
      load,
      rows: 100,
      currentPage: 1,
    };
  },
};
