
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
import AdminImageUpload from  "@/components/AdminImageUpload.vue";
export default {
     name:"AdminPackageCreate",
      components: {
     'AdminImageUpload': AdminImageUpload,
       },
 setup() {
    const data  =reactive({
      package_title:'',
      package_label:'',
      package_detail:'',
      price:'',
      image_url:'',
      country_id:'',

    });
   
      const router = useRouter();
     const countries =ref([]);

    onMounted(async ()=>{
      const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
       

    });

const submit =async ()=>{

await axios.post(`/packages`,data);

await router.push('/packages');
}


    return {
        data,
        countries,
      submit
    };
  },
};
