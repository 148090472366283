
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
  name: "AdminUserEdit",
  setup() {
    const data  =reactive({
      full_name:'',
      email:'',
      bussiness_name:'',
      phone:'',
      account_type:'',
      is_postpaid:'',
      role_id:'',
    country_id:'',
    prep_time:'15',
    invoice_address:'',
    is_active:true,
    stacked_order:false
    });
   
      const router = useRouter();
      const route = useRoute();
     const roles =ref([]);
   const countries =ref([]);

    onMounted(async ()=>{
      const  rolesResponse = await axios.get('roles');
      roles.value = rolesResponse.data;

        const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
      const response = await axios.get(`/users/${route.params.id}`)
      data.full_name =response.data.full_name;
      data.email =response.data.email;
      data.bussiness_name =response.data.bussiness_name;
        data.account_type =response.data.account_type;
      data.phone =response.data.phone;
      data.role_id =response.data.role.id;
      data.is_postpaid = response.data.is_postpaid;
      data.country_id = response.data.country.id;
      data.prep_time = response.data.prep_time;
      data.is_active = response.data.is_active;
      data.stacked_order = response.data.stacked_order;
    });

      const submit =async ()=>{

      await axios.put(`/users/${route.params.id}`,data);

      await router.push('/users');
      }


    return {
        data,
        roles,
        countries,
      submit
    };
  },
};
