
import { onMounted, ref } from 'vue';
import   axios   from 'axios';
import {Order} from '@/models/order'
import moment from 'moment'; 
import  AdminPagination from '@/components/AdminPagination.vue'

export default {
    name: "AdminOrderItemList",
    components: {AdminPagination} ,
  methods: {
    dateTime(value:any) {
      return moment(value).format("YYYY-MM-DD h:mm:ss a");
    }
  },
    setup(){
      const orders = ref([]);
      const lastPage = ref(0);
      const selected =ref(0);
       const rider = ref([]);
      



  const load = async (page=1)=>{
   const {data} = await  axios.get(`orders?page=${page}`);
          orders.value =data.data;
        lastPage.value=data.meta.last_page;   
    }

      onMounted(load);

      const select =async (id:number,rider_id:any) =>{
      selected.value=  selected.value  !== id ? id : 0;
      if(rider_id){
        
        const {data} = await  axios.get(`riders/${rider_id}`);
         rider.value =data;
      }
      }
 

const del =async (id:number)=>{
  if(confirm('Are You Sure?')){
    await axios.delete(`orders/${id}`);

    orders.value = orders.value.filter((u: Order)=> u.id !== id);

  }

}
const delraterange =async (id:number)=>{
  if(confirm('Are You Sure?')){
    await axios.delete(`rateranges/${id}`);

   

  }

}


const exportCSV = async ()=>{
  const {data} = await axios.post('orders/export',{},{responseType:'blob'});
  const blob = new Blob([data],{type:'text/csv'});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(data);
  link.download = 'orders.csv';
  link.click();


}

 

      return {
        orders,
        lastPage,
        del,
        delraterange,
        load,
        selected,
        select,
        exportCSV,
        rider 
          
      }
      
    }    
}
 
