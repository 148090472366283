
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import {  useRouter } from 'vue-router';
export default {
  name: "AdminShiftCreate",
  setup() {
    const form  = reactive({
      allow_rider:'',
      shift_fee:'',
      start_time:'',
      end_time:'',
      shift_date:'',
      city:''

    });
      const cities =ref([]);
      const router = useRouter();
      

    onMounted(async ()=>{
      
      const  cityResponse = await axios.get('cities/user/countryall');
      cities.value = cityResponse.data;

    });

    const submit =async ()=>{

    await axios.post('shifts',form);

    await router.push('/shifts');
    }


    return {
        form,
        cities,
      submit
    } 
  } 
} 
