
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
export default {
     name:"AdminBookingCreate",
 setup() {
    const data  =reactive({
        name:'',
        name_urdu:'',
        country_id:'',
        city_topic:'',
        is_show:'true',
        is_second_order_cron:'true',
        is_third_order_cron:'false',
        booking_price_fixed:0,
        booking_price_flexible:0,
        booking_flexible_note:''

    });
   

    const router = useRouter();
     const countries =ref([]);

    onMounted(async ()=>{
      const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
     

    });

const submit =async ()=>{

await axios.post(`/cities`,data);

await router.push('/cities');
}

    return {
        data,
        countries,
      submit
    };
  },
};
