
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
import AdminImageUpload from  "@/components/AdminImageUpload.vue";
export default {
     name:"AdminPackageEdit",
      components: {
     'AdminImageUpload': AdminImageUpload,
       },
 setup() {
    const data  =reactive({
      package_title:'',
      package_label:'',
      package_detail:'',
      price:'',
      image_url:'',
      country_id:'',

    });
   
      const router = useRouter();
      const route = useRoute();
     const countries =ref([]);

    onMounted(async ()=>{
      const  countryResponse = await axios.get('countries');
      countries.value = countryResponse.data.data;
      const response = await axios.get(`/packages/${route.params.id}`);
      console.log(response);
      data.package_title =response.data.package_title;
      data.package_label =response.data.package_label;
      data.package_detail =response.data.package_detail;
      data.price =response.data.price;
      data.image_url =response.data.image_url;
      data.country_id =response.data.country?.id;
      
    });

const submit =async ()=>{

await axios.put(`/packages/${route.params.id}`,data);

await router.push('/packages');
}


    return {
        data,
        countries,
      submit
    };
  },
};
