
import { reactive } from "vue";
import axios from "axios";
import {  useRouter } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
     name:"AdminCmsCreate",
      components: {
     QuillEditor
       },
 setup() {
    const data  =reactive({
      title:'',
      detail:'',
      slug:'',
   

    });
   
      const router = useRouter();
    
     
const submit =async ()=>{

await axios.post(`/cms`,data);

await router.push('/cms');
}


    return {
        data,
        submit
    };
  },
};
