export class Country{
    constructor(
        public id: number=0,
        public name: string='',
        public country_code: string='',
        public currency_short_name: string='',
        public curreny_standard_name: string='',
        public country_time_zone: string='',
        public country_topic: string='',
        public is_show: boolean=true,

    ){

    }
}