<template >
  <main class="form-signin">
    <form @submit.prevent="submit">
      <h1 class="h3 mb-3 fw-normal">Please Register</h1>

      <div class="form-floating">
        <input
          type="text"
          v-model="fullName"
          class="form-control"
          placeholder="Full Name"
          required
        />
        <label for="floatingInput">Full Name</label>
      </div>

      <div class="form-floating">
        <input
          v-model="bussinessName"
          type="text"
          class="form-control"
          placeholder="business name"
          required
        />
        <label for="floatingInput">Business name</label>
      </div>

      <div class="form-floating">
        <input type="text"  v-model="phone"   class="form-control" placeholder="Phone" required />
        <label for="floatingInput">Phone</label>
      </div>
      <div class="form-floating">
        <input type="text"   v-model="userType"   class="form-control" placeholder="Phone" required />
        <label for="floatingInput">User Type</label>
      </div>

      <div class="form-floating">
        <input type="email"  v-model="email" class="form-control" placeholder="Email" required />
        <label for="floatingInput">Email address</label>
      </div>

      <div class="form-floating">
        <input
          type="password"
           v-model="password"
          class="form-control"
          placeholder="Password"
          required
        />
        <label for="floatingPassword">Password</label>
      </div>
      <div class="form-floating">
        <input
          type="password"
           v-model="confirmPassword"
          class="form-control"
          placeholder="Confirm Password"
          required
        />
        <label for="floatingPassword">Confirm Password</label>
      </div>

      <button class="w-100 btn btn-lg btn-primary" type="submit">Submit</button>
    </form>
  </main>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';
export default {
  name: "AdminUserRegister",
  setup() {
    const fullName = ref("");
    const email = ref("");
    const bussinessName = ref("");
    const phone = ref("");
    const userType = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const router = useRouter();

    const submit = async () => {
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
     const response = await  axios.post('/register',{
            full_name:fullName.value,
            email:email.value,
            bussinessName:bussinessName.value,
            phone:phone.value,
            user_type_id:parseInt(userType.value),
            password:password.value,
            password_confirm:confirmPassword.value
  
        });

    await router.push('/login');

}

    return {
      fullName,
      email,
      bussinessName,
      phone,
      userType,
      password,
      confirmPassword,
      submit
    };
  },
};
</script>
<style  scoped>
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>