
import { onMounted, ref } from "vue";
import axios from "axios";
import { PackageSize } from "@/models/packagesize";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "AdminPackageList",
  components: { VPagination },
  setup() {
    const packages = ref([]);

    const lastPage = ref(0);

    const load = async (page = 1) => {
      const { data } = await axios.get(`packages?page=${page}&take=25`);
      packages.value = data.data;
      lastPage.value = data.meta.last_page;
    };

    onMounted(load);

    const del = async (id: number) => {
      if (confirm("Are You Sure?")) {
        //   await axios.delete(`packages/${id}`);

        packages.value = packages.value.filter((u: PackageSize) => u.id !== id);
      }
    };

    return {
      packages,
      lastPage,
      del,
      load,
      rows: 100,
      currentPage: 1,
    };
  },
};
