
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AdminOrderEdit",
  setup() {
    const data = reactive({
      orderstatus_id: "",
      cancel_note: "",
      courier_note: "",
    });

    const datapush = reactive({
      driver_id: "",
    });
    const router = useRouter();
    const route = useRoute();
    const orderstatuses = ref([]);
    const riders = ref([]);

    onMounted(async () => {
      const statusResponse = await axios.get("orderstatus");
      orderstatuses.value = statusResponse.data;

      const response = await axios.get(`/orders/${route.params.id}`);

      data.orderstatus_id = response.data.orderstatus.id;
      data.cancel_note = response.data.cancel_note;
      data.courier_note = response.data.courier_note;

      datapush.driver_id = response.data.driver?.id;
      //var countryid = response.data.country.id;

      const riderResponse = await axios.get(
        `/drivers/nearist/online/${route.params.id}`
      );
      riders.value = riderResponse.data;
    });

    const submit = async () => {
      await axios.put(`/orders/${route.params.id}`, data);

      await router.push("/");
    };

    const submitpush = async () => {
      await axios.put(`/orders/socketpush/${route.params.id}`, datapush);

      await router.push("/");
    };

    return {
      data,
      orderstatuses,
      riders,
      datapush,
      submitpush,
      submit,
    };
  },
};
